.bandeau_expertises{

	text-align: center;

	.bandeau_expertises_expertise{

		text-align: center;
		display: block;
		width: 225px;
		color: @noir;
		padding-bottom: 20px;
		margin: 0 auto;
		box-sizing: border-box;
		//padding: 0 30px 20px;

		img{
			display: block;
			width: 225px;
			margin: 0 auto;
		}

		.bandeau_expertises_expertise_titre{
			font-size: 16px;
			font-family: @font_RH;
			display: block;
			padding: 20px 0 10px;
			text-transform: uppercase;
		}

		p{
			height: 60px;
			padding: 0 0 10px;
			max-width: 225px;
			margin: 0 auto 10px;
		}

	}

}

@media screen and (min-width: 640px) {

	.bandeau_expertises{

		width: 490px;
		margin: 20px auto 0;

		.bandeau_expertises_expertise{
			display: inline-block;
			&:nth-child(even){
			margin-left:40px;
			}
		}

	}

}
@media screen and (min-width: 1000px) {
	.bandeau_expertises .bandeau_expertises_expertise:nth-child(even) {
		margin-left: 10px;
	}
}

@media screen and (min-width: 1024px) {

	.bandeau_expertises{

		width: 100%;
		padding-top: 30px;

		.bandeau_expertises_expertise{
			display: inline-block;
			margin: 0 10px;
		}

	}

}
