.accueil-produit{
    .tetiere {
        padding: 100px 0;
    }

    .titre{
        strong{
            display: inline-block;
        }
    }
    .content_product{
        .cta-outer{
            margin:30px auto;
            text-align: center;
        }
        .one_product{

            max-width:306px;
            display:block;
            margin: 0 auto 30px;

            .one_product_info{
                position: relative;
                display: table;
                width: 306px;
                .one_product_visuel_besoin{
                    width:100%;
                    height:auto;
                    z-index:-1;
                    display: block;
                    position: absolute;
                }
            }
            .one_product_btn_voir_plus{
                position: absolute;
                right:0;
                bottom:0;
                display:block;
                width:71px;
                height:44px;
                background: url(../img/btn_voir_plus.png);
                z-index: 6;
                color: #FFF;
                font-size: 30px;
                box-sizing: border-box;
                padding-top: 14px;
                img{
                    width: auto;
                }
            }
            .one_product_bloc_texte {
                top:0;
                left:0;
                z-index:5;
                padding:0 25px;
                box-sizing: border-box;
                display:table-cell;
                vertical-align: middle;
                width: 100%;
                height: 100%;
                color:@blanc;
                background-color: rgba(0,0,0,0.8);
                opacity: 0;
                .mixin-transition(opacity 250ms linear);
                &:hover{
                    opacity: 1;
                }
            }
            .one_product_nom_produit{
                text-align: center;
                font-size: 16px;
                font-family:@font_RH;
                text-transform: uppercase;
                padding-top: 30px;
            }
        }
    }



    .gammes_shampooings{
        .titre {
            padding: 60px 20px;
        }
        .one_gamme {
            padding-bottom:35px;
            position: relative;
            color: @noir;
            .one_gamme_nom_produit{
                text-align: center;
                font-size: 16px;
                font-family:@font_RH;
                text-transform: uppercase;
                margin:20px auto;
            }
            p{
                padding:0 40px;
            }
            .btn_voir_plus_gamme{
                color:@orange;
                font-size:40px;
                position: absolute;
                right: 40px;
                top: 130px;
                &::before{
                    content: ' ';
                    width: 0;
                    height: 19px;
                    display: inline-block;
                    border-style: solid;
                    border-width: 1px;
                    border-color: @orange;
                    -ms-transform: rotate(25deg);
                    -webkit-transform: rotate(25deg);
                    -moz-transform: rotate(25deg);
                    transform: rotate(25deg);
                    position: relative;
                    left: 5px;
                    top: -4px;
                }
            }
        }
    }


}




@media screen and (min-width: 640px) {
    .accueil-produit{
        .content_product{
            text-align: left;
            padding: 0 66px;
            .one_product{
                display: inline-block;
                width: 100%;
                vertical-align: top;
                text-align: center;
                &:nth-child(odd){
                    margin-right:20px;
                }
            }
            .cta-outer {
                margin: 20px auto 0;
                text-align: center;
            }
        }
        .gammes_shampooings{
            .content_gammes_shampooings{
                text-align: left;
                .one_gamme {
                    width: 32%;
                    display: inline-block;
                    text-align: center;
                    vertical-align: top;
                    .btn_voir_plus_gamme{
                        right: 10px;
                    }
                    .one_gamme_nom_produit {
                        padding: 0 30px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .accueil-produit{
        .titre{
            padding: 35px;
        }
        .content_product{
            max-width:980px;
            padding:0;
            margin:0 auto;
            //&.colonne{
            //    column-count: 3;
            //    -webkit-column-fill: auto; /* Chrome, Safari, Opera */
            //    -moz-column-fill: auto; /* Firefox */
            //    column-fill: auto;
            //    display:block;
            //}
            .one_product{
              //  vertical-align: inherit;
              //  -webkit-column-break-inside: avoid; /* Chrome, Safari */
              // page-break-inside: avoid;           /* Theoretically FF 20+ */
              // break-inside: avoid-column;         /* IE 11 */
                margin-left: 5px;
                margin-right: 5px;
                //display: block;
                /*margin-bottom:0;*/
                /*&:before{
                    content:'';
                    display:block;
                    width:100%;
                    height:30px;
                }*/
                &:nth-child(odd) {
                    margin-right: 10px;
                }
                &:last-child {
                    margin-bottom:0;
                }
                /*&:nth-child(4),&:nth-child(5),&:nth-child(6),
                &:nth-child(10),&:nth-child(11),&:nth-child(12),
                &:nth-child(16),&:nth-child(17),&:nth-child(18),
                &:nth-child(22),&:nth-child(23),&:nth-child(24) {
                    vertical-align: top;
                }*/
                .one_product_nom_produit{
                    height:30px;
                }
            }
        }
        .cta-outer{
            margin-top: 50px;
        }
        .gammes_shampooings{
                max-width:980px;
                margin:0 auto;
            .content_gammes_shampooings{
                padding: 30px 0;
                .one_gamme{
                    width: 24%;
                    p{
                        padding: 0 20px;
                    }
                    .one_gamme_nom_produit  {
                        margin: 30px auto 15px;
                    }
                }
            }
            .titre{
                strong{
                    display:block;
                }
            }
        }
    }
}
