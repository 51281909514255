.devenir-franchise{
    .devenir-franchise_etapes{
        background: #f6f6f6;
        padding:40px 0;
        .devenir-franchise___etapes__single{
            .devenir-franchise___etapes__number{
                display:block;
                font-family:@font_RM;
                font-size:27px;
                color:#e67a2a;
                background: url(../img/background_number_etapes_franchise.png) no-repeat center;
                width: 40px;
                height: 40px;
                margin: 0 auto 18px;
                line-height: 36px;
            }
            p{
                max-width: 220px;
                margin: 0 auto 30px;
                strong {
                    font-family:@font_RB;
                }
            }
            &:last-child p{
                margin-bottom:0;
            }
        }
    }
    .formulaire__table-form{
        .tr-section:first-of-type{
            td{
            padding-top:0;
            }
        }    
    }
}

@media screen and (min-width: 640px) {
    .devenir-franchise{
        .devenir-franchise_etapes{
            padding: 50px 0;
            .devenir-franchise___etapes__single{
                max-width: 550px;margin: 0 auto;
                .devenir-franchise___etapes__number{
                    display:inline-block;
                    font-size:23px;
                    width: 40px;
                    height: 40px;
                    margin: 0 auto 18px;
                    line-height: 35px;
                    vertical-align: top;
                }
                p{
                    display:inline-block;
                    max-width: 490px;
                    text-align:left;
                    padding-left: 16px;
                    box-sizing: border-box;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .devenir-franchise{
        .devenir-franchise_etapes{
            .devenir-franchise___etapes__single{
                .devenir-franchise___etapes__number{
                    font-size:26px;
                }
            }
        }
    }
}
