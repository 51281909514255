body{
	text-align: center;
	font-family: @font_RR;
	font-size: 14px;
	line-height: 20px;
	background-color: @blanc;
	color: @noir;
	padding-top: 50px;
	letter-spacing: 1px;
}
body, html{
	overflow-x: hidden;
}
input, textarea, select {
   -webkit-appearance: none;
}

.wrap{
	max-width: 980px;
	margin: 0 auto;
}

/* PHONE */

.phone{
	color: @noir;
	a{
		color: @noir;
	}
}

textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
     -webkit-appearance: none;
     border-radius: 0;
     -webkit-border-radius: 0;
}

.hide_mobile_ib{
	display: none;
}

/* POLICES */

.font_RR{
	font-family: @font_RR;
}
.font_RM{
	font-family: @font_RM;
}
.font_RB{
	font-family: @font_RB;
}
.font_RH{
	font-family: @font_RH;
}
.font_PB{
	font-family: @font_PB;
}

/* TITRES */

.titre{
	font-family: @font_RR;
	text-transform: uppercase;
	color: @noir;
	text-align: center;
	padding: 20px;
	font-size: 22px;
	line-height: 26px;
	strong{
		font-family: @font_PB;
		display: block;
	}

	&.titre_strong_inline{
		strong{
			display: inline;
		}
	}
}

.titre_2{
	font-family: @font_RR;
	text-transform: uppercase;
	color: @noir;
	text-align: center;
	font-size:22px;
	padding: 30px 0 15px 0;
	display:block;
}

/* TEXT DECORATION */

.text_uppercase{
	text-transform: uppercase;
}

/* ALIGNEMENTS */

.align_center{
	text-align: center;
}
.align_justify{
	text-align: justify;
}
.align_left{
	text-align: left;
}
.align_right{
	text-align: right;
}


/* COULEURS */

.blanc{
	color: @blanc;
}
.orange{
	color: @orange;
}
.parme{
	color: @parme;
}
.jaune{
	color: @jaune;
}
.vert{
	color: @vert;
}
.rose{
	color: @rose;
}
.bleu{
	color: @bleu;
}
.noir{
	color: @noir;
}

/* BOUTONS */

.cta{
	text-transform: uppercase;
	text-decoration: none;
	font-family: @font_RB;
	font-size: 12px;
	display: inline-block;
	line-height: 18px;
	height: 18px;
	padding-right: 34px;
	position: relative;
	overflow: hidden;
	letter-spacing: 1px;
	color: @noir;
	cursor: pointer;
	.mixin-transition(all 125ms linear);

	.hide_mobile{
		display: none;
	}

	.hide_mobile_ib{
		display: inline-none;
	}

	&:before{
		content: ' ';
		width: 12px;
		height: 12px;
		background-image: url(../img/sprite-cta.png);
		background-repeat: no-repeat;
		background-size: 28px 75px;
		position: absolute;
		right: 0;
		top: 3px;
		.mixin-transition(all 125ms linear);
	}


	&:after{
		content: ' ';
		width: 0;
		height: 30px;
		display: inline-block;
		border-style: solid;
		border-width: 1px;
		border-color: @noir;
		.mixin-rotate();
		position: absolute;
		right: 24px;
		top: 0;
		.mixin-transition(all 125ms linear);
	}

	&:hover{
		padding-left: 0;
		&:after{
			//right: 0;
			.mixin-rotate(0deg);
		}

		&:before{
			right: 10px;
			.mixin-rotate(180deg);
		}
	}

	&.border{
		border-style: solid;
		border-width: 2px;
		.mixin-border-radius();
		line-height: 38px;
		height: 38px;
		padding-left: 34px;
		padding-right: 64px;
		font-size: 14px;
		&:after{
			height: 50px;
			right: 44px;
			top: -5px;
		}
		&:before{
			right: 18px;
			top: 13px;
		}
	}

	&.blanc{
		color: @blanc;
		border-color: @blanc;
		background-position: -2px 0;
		&:after{
			border-color: @blanc;
		}
		&:before{
			background-position: -2px 0;
		}
	}
	&.orange{
		color: @orange;
		border-color: @orange;
		&:after{
			border-color: @orange;
		}
		&:before{
			background-position: 0px -28px;
		}
	}
	&.parme{
		color: @parme;
		border-color: @parme;
		&:after{
			border-color: @parme;
		}
		&:before{
			background-position: -14px -28px;
		}
	}
	&.jaune{
		color: @jaune;
		border-color: @jaune;
		&:after{
			border-color: @jaune;
		}
		&:before{
			background-position: -0px -14px;
		}
	}
	&.vert{
		color: @vert;
		border-color: @vert;
		&:after{
			border-color: @vert;
		}
		&:before{
			background-position: -14px -42px;
		}
	}
	&.rose{
		color: @rose;
		border-color: @rose;
		&:after{
			border-color: @rose;
		}
		&:before{
			background-position: -2px -42px;
		}
	}
	&.bleu{
		color: @bleu;
		border-color: @bleu;
		&:after{
			border-color: @bleu;
		}
		&:before{
			background-position: -14px 0;
		}
	}
	&.noir{
		color: @noir;
		border-color: @noir;
		&:after{
			border-color: @noir;
		}
		&:before{
			background-position: -14px -14px;
		}
	}
	&.position{
		&:before{
			background-position: -2px -56px;
			width: 12px;
			height: 17px;
			    top: 11px;
		}
	}

	&.cta_trouver_salon{
		border-width: 0;
		background-color: @orange;
		&:before{
			background-image: url(../img/locator.svg);
			background-position: 0 0 !important;
			.mixin-rotate(0deg) !important;
			background-size: 12px 17px !important;
			width: 12px;
			height: 17px;
			top: 10px;
		}
	}

}

/* CLEARFIX */

.clearfix{
	&:after{
		content: ' ';
		display: block;
		clear: both;
		width: 100%;
		height: 0;
	}
}

/* PARAGRAPHE */
p{
	&.sous-titre{
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}
}


.hide-mobile{
	display: none;
}
.hide-desktop{
	display: block;
}
.only-desktop{
	display: none;
}

@media screen and (min-width: 768px) {

	body{
		padding-top: 90px;
	}

	p{
		&.sous-titre{
			width: 60%;
		}
	}

	.cta{

		.hide_mobile{
			display: inline;
		}
	}


	/* TITRES */

	.titre{
		font-size: 30px;
		line-height: 34px;
		strong{
			display: inline;
		}
	}

	.titre_2{
		font-size:30px;
		line-height: 34px;
	}


	.hide-mobile{
		display: block;
	}
	.hide-desktop{
		display: block;
	}
	.only-desktop{
		display: none;
	}


}

@media screen and (min-width: 1024px) {

	body{
		padding-top: 98px;
	}

	p{
		&.sous-titre{
			width: 460px;
		}
	}
	.hide_mobile_ib{
		display: inline-block;
	}

	.hide-mobile{
		display: block;
	}
	.hide-desktop{
		display: none;
	}
	.only-desktop{
		display: block;
	}

}
