.page-404{
	
	.tetiere{
		padding-top: 80px;
		padding-bottom: 80px;
		background-image: url(../img/tetiere-404.jpg);
		.cta{
			margin-top: 40px;
			border-color: rgba(255,255,255,0)
		}
	}
}

@media screen and (min-width: 640px) {
	.page-404{
		.bottom_block{
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}
}


@media screen and (min-width: 1024px) {
	.page-404{
		.bottom_block{
			margin-top: 50px;
			margin-bottom: 50px;
		}
	}

}