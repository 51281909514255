.bloc_newsletter{
	text-align: center;
	padding: 110px 35px 18px;
	border: solid 8px @orange;
	background-color: @blanc;
	background-repeat: no-repeat;
	width: 100%;
	box-sizing: border-box;
	background-position: center 35px;
	background-image: url(../img/bg-newsletter.png);
	max-width: 306px;
	margin: 0 auto 15px;
	p{
		padding-bottom: 20px;
	}

	&.full_width{
		max-width: 90%;
		margin-top: 50px;
		margin-bottom: 50px;
	}


		.bloc_newsletter_form{
			background-color: @blanc;
			margin-top: 17px;
			border: solid 2px #e67a2a;
			margin-left: auto;
			margin-right: auto;
			margin-top: 0;
			margin-bottom: 10px;
			width: 100%;

			input[type="text"]{
				line-height: 42px;
				height: 42px;
				background-color: @blanc;
				width: ~"calc(100% - 45px)";
				font-size: 12px;
				color: @noir;
				font-family: @font_RR;
				float: left;
				border: none;
				padding: 0 20px;
				box-sizing: border-box;
				letter-spacing: 1px;
				&::-webkit-input-placeholder {
				  color: @noir;
				}
				&::-moz-placeholder { 
				  color: @noir;
				}
				&:-ms-input-placeholder {
				  color: @noir;
				}
				&:-moz-placeholder {
				  color: @noir;
				}
			}
			input[type="submit"],
			button{
				line-height: 42px;
				height: 42px;
				background-color: @blanc;
				width: 45px;
				float: left;
				font-size: 12px;
				color: @noir;
				font-family: @font_RB;
				border: none;
				position: relative;
				&:before{
					content: ' ';
					width: 0;
					height: 15px;
					display: inline-block;
					border-style: solid;
					border-width: 1px;
					border-color: @noir;
					.mixin-rotate();
					position: absolute;
					left: 2px;
					top: 12px;
				}
			}
		}

}

@media screen and (min-width: 640px) {

	.homepage{
		.bloc_newsletter{
			padding: 160px 35px 50px;
			background-position: center 85px;
		}

	}
	.bloc_newsletter {
		&.full_width{
			border-right: none;
			border-left: none;
		}
			.bloc_newsletter_form{
				width: 300px;
			}
	}

}
@media screen and (min-width: 1170px) {

	.bloc_newsletter{

		.bloc_newsletter_form{
			width: 300px;
		}
		&.full_width{
			max-width: 1170px;
		}

	}


}
