.plan-site{
	text-align: left;
	padding: 25px 25px 25px 40px;
	h1{
		padding-bottom: 25px;
		text-transform: uppercase;
		font-size: 26px;
		line-height: 30px;
		font-family: @font_RR;
		text-align: center;
		strong{
			font-family: @font_RH;
		}
	}
	aside{
		padding-bottom: 25px;
		ul, ol{
			list-style: none;
		}
		a{
			color: @orange;
			text-transform: uppercase;
			font-family: @font_RH;
		}
		li{
			display: block;
			padding-bottom: 10px;
		}
		.sub-menu{
			padding-top: 15px;
			padding-left: 10px;
			a{
				color: @noir;
				text-transform: capitalize;
			}
			.sub-menu{
				padding-top: 5px;
				a{
					font-family: @font_RR;
				}
				li{
					&:nth-last-child(1){
						padding-bottom: 0;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 640px) {

	.plan-site{
		padding: 30px 40px;
		text-align: center;
		h1{
			font-size: 38px;
			line-height: 45px;
			padding-bottom: 30px;
		}

		aside{
			text-align: left;
			display: inline-block;
			vertical-align: top;
			width: 40%;
			padding-left: 30px;
			padding-right: 30px;
			box-sizing: border-box;
		}

	}

}

@media screen and (min-width: 1024px) {

	.plan-site{
		padding: 30px 0;
		width: 960px;
		margin-left: auto;
		margin-right: auto;

		h1{
			padding-bottom: 50px;
		}

		aside{
			width: 24%;
			padding-left: 0;
			padding-right: 0;
		}

	}

}