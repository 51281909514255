.bandeau_mag,
.liste_mag{
	margin: 15px auto 0;
	text-align: center;
	color: @noir;
	width: 326px;

	.bandeau_mag_item{
		display: inline-block;
		vertical-align: top;
		width: 306px;
		color: @noir;
		margin: 15px 8px;
		p{
			text-align: left;
			padding: 15px;
		}
	}

	.bandeau_mag_visu{
		position: relative;
		img{
			display: block;				
			.mixin-grayscale();
		}
		.figure{
			position: absolute;
			bottom: 0;
			right: 0;
			color: @blanc;
			text-align: center;
			text-transform: uppercase;
			padding: 35px 0 12px 20px;
			width: 200px;
			overflow: hidden;

			background-position: left top;
			background-repeat: no-repeat;
			background-size: 110% auto;
			strong{
				font-family: @font_RH;
				display: block;
			}

			p{
				position: relative;
				z-index: 2;
				padding: 0;
				text-align: center;
				strong{
					position: relative;
					z-index: 2;
				}
			}

			/*&:before,
			&:after{
				content: ' ';
				display: block;
				position: absolute;
				width:1px;
				height:100px;
				border:1px solid @noir;
				border-width:120px;
				z-index: 1;
				border-color:transparent transparent transparent @noir;
			}

			&:before{
				top: -100px;
				left: -120px;
				.mixin-rotate(215deg);
			}

			&:after{
				top: -40px;
				left: 50px;
				.mixin-rotate(125deg);
			}*/
		}
	}

	.owl-item{
		opacity: 0.6;
		.cta{
			color: @noir;
			&:before{
				background-position: -14px -14px;
			}
			&:after{
				border-color: @noir;
			}
		}
		&.active{
			opacity: 1;			
			.cta{
				&.blanc{
					color: @blanc;
					border-color: @blanc;
					background-position: -2px 0;
					&:after{
						border-color: @blanc;
					}
					&:before{
						background-position: -2px 0;
					}
				}
				&.orange{
					color: @orange;
					border-color: @orange;
					&:after{
						border-color: @orange;
					}
					&:before{
						background-position: -2px -28px;
					}
				}
				&.parme{
					color: @parme;
					border-color: @parme;
					&:after{
						border-color: @parme;
					}
					&:before{
						background-position: -14px -28px;
					}
				}
				&.jaune{
					color: @jaune;
					border-color: @jaune;
					&:after{
						border-color: @jaune;
					}
					&:before{
						background-position: -2px -14px;
					}
				}
				&.vert{
					color: @vert;
					border-color: @vert;
					&:after{
						border-color: @vert;
					}
					&:before{
						background-position: -14px -42px;
					}
				}
				&.rose{
					color: @rose;
					border-color: @rose;
					&:after{
						border-color: @rose;
					}
					&:before{
						background-position: -2px -42px;
					}
				}
				&.bleu{
					color: @bleu;
					border-color: @bleu;
					&:after{
						border-color: @bleu;
					}
					&:before{
						background-position: -14px 0;
					}
				}
			}

			.bandeau_mag_visu{
				img{		
					.mixin-grayscale(0%);
				}
				.figure{

					&.noir{
						/*&:before,
						&:after{
							border-color:transparent transparent transparent @noir;
						}*/
						background-image: url(../img/formes/noir.svg);
					}
					&.parme{
						/*&:before,
						&:after{
							border-color:transparent transparent transparent @parme;
						}*/
						background-image: url(../img/formes/parme.svg);
					}
					&.rose{
						/*&:before,
						&:after{
							border-color:transparent transparent transparent @rose;
						}*/
						background-image: url(../img/formes/rose.svg);
					}
					&.vert{
						/*&:before,
						&:after{
							border-color:transparent transparent transparent @vert;
						}*/
						background-image: url(../img/formes/vert.svg);
					}
					&.bleu{
						/*&:before,
						&:after{
							border-color:transparent transparent transparent @bleu;
						}*/
						background-image: url(../img/formes/bleu.svg);
					}
					&.orange{
						/*&:before,
						&:after{
							border-color:transparent transparent transparent @orange;
						}*/
						background-image: url(../img/formes/orange.svg);
					}

				}

			}
		}
	}

	.bandeau_mag_titre{
		font-size: 16px;
		font-family: @font_RR;
		text-transform: uppercase;
		padding-top: 18px;
		display: block;
		strong{
			font-family: @font_RH;
		}
		p{
			padding: 0;
			text-align: center;
		}
	}



}

@media screen and (min-width: 1024px) {

	.bandeau_mag,
	.liste_mag{
		width: 100%;
		.bandeau_mag_visu{
			img{			
				.mixin-grayscale(0%);
			}
			.figure{

				&.noir{
					&:before,
					&:after{
						border-color:transparent transparent transparent @noir;
					}
				}
				&.parme{
					&:before,
					&:after{
						border-color:transparent transparent transparent @parme;
					}
				}
				&.rose{
					&:before,
					&:after{
						border-color:transparent transparent transparent @rose;
					}
				}
				&.vert{
					&:before,
					&:after{
						border-color:transparent transparent transparent @vert;
					}
				}
				&.bleu{
					&:before,
					&:after{
						border-color:transparent transparent transparent @bleu;
					}
				}
				&.orange{
					&:before,
					&:after{
						border-color:transparent transparent transparent @orange;
					}
				}
				&.jaune{
					&:before,
					&:after{
						border-color:transparent transparent transparent @jaune;
					}
				}

			}
		}

	}

}
