.fiche-salon{

	.owl-thumbs{
		display: none;
	}

	.bandeau_20_pourcent{
		background-color: @orange;
		color: @blanc;
		border-top: solid 2px @blanc;
		text-align: center;
		padding: 12px 45px;
		text-transform: uppercase;
		font-family: @font_RR;
		font-size: 14px;
		line-height: 23px;
		strong{
			font-family: @font_RH;
			display: block;
		}
		img{
			display: inline-block;
			vertical-align: middle;
			height: 23px;
			width: auto;
		}
	}

	.fiche-salon_top_maps{
		width: 100%;
		height: 170px;
		iframe{
			width: 100%;
			height: 170px;
		}
	}

	.fiche-salon_top_info{
		text-align: left;
		padding: 15px 5px 15px 15px;

		h1{
			text-transform: uppercase;
			font-size: 22px;
			line-height: 28px;
			width: 75%;
			padding-bottom: 20px;

			span{
				font-size: 30px;
				display: block;
				padding-top: 8px;
			}
		}

		ul{
			li{
				display: block;
				padding-bottom: 20px;
				position: relative;
				padding-left: 30px;

				.picto-fiche{
					display: block;
					background: url(../img/sprite-fiche-salon.png) no-repeat;
					overflow: hidden;
					text-indent: -9999px;
					text-align: left;
					background-size: 24px auto;
					position: absolute;

					&.picto-fiche-itineraire{
						background-position: -2px 0;
						width: 20px;
						height: 20px;
						left: 0;
						top: 0;
					}
					&.picto-fiche-email{
						background-position: -2px -21px;
						width: 19px;
						height: 18px;
						left: 1px;
						top: 2px;
					}
					&.picto-fiche-horaire{
						background-position: -2px -41px;
						width: 19px;
						height: 18px;
						left: 1px;
						top: 2px;
					}
					&.picto-fiche-telephone{
						background-position: -2px -62px;
						width: 16px;
						height: 16px;
						left: 3px;
						top: 5px;
					}
					&.picto-fiche-map{
						background-position: -2px -79px;
						width: 12px;
						height: 18px;
						left: 3px;
						top: 2px;
					}

				}

				.horaire{
					display: block;
					padding-right: 20px;
					max-width: 270px;
					.horaire_jour{
						float: left;
					}
					.horaire_heures{
						float: right;
					}
				}

			}
		}
	}

	.fiche-salon_club_fidelite{
		width: ~'calc(100% - 10px)';
		text-align: center;
		color: @blanc;
		padding: 30px 20px 20px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(../img/bg-participe.jpg);
		box-sizing: border-box;
		p{
			font-size: 20px;
			line-height: 23px;
			padding-bottom: 20px;
			text-transform: uppercase;
		}
	}

	.fiche-salon_contenu{

		.slider-salon{
			max-width: 437px;
			margin: 0 auto;

			img{
				max-width: 100%;
			}
			.owl-dots{
				display: none;
			}

			.owl-stage-outer{
				overflow: hidden;
				width: 100%;
				margin: 0 auto;
			}

			.owl-thumbs{
				text-align: left;
				display: block;
				button {
				    background: none;
				    border: 0;
				    color: inherit;
				    /* cursor: default; */
				    font: inherit;
				    line-height: normal;
				    overflow: visible;
				    padding: 0;
				    -webkit-appearance: button; /* for input */
				    -webkit-user-select: none; /* for button */
				       -moz-user-select: none;
				        -ms-user-select: none;

				    display: inline-block;
				    margin-right: 15px;
				    margin-top: 15px;
				}
				img{
					max-width: 62px;
					max-height: 62px;
					display: block;
				}
			}

		}

		.fiche-salon_details{
			dt{
				text-transform: uppercase;
				font-size: 18px;
				border-top: solid 1px @orange;
				line-height: 50px;
				padding-left: 10px;
				text-align: left;
				margin: 0 15px;
				display: block;
				width: ~'calc(100% - 30px)';
				box-sizing: border-box;
				position: relative;

				&:after{
					content: '+';
					position: absolute;
					right: 10px;
					font-size: 34px;
					color: @noir;
					font-family: @font_RR;
				}

				&.active{
					border-bottom: solid 1px @orange;

					&:after{
						content: '-';
					}
				}
			}
			dd{
				padding: 30px 15px;
				//display: none;
			}
		}

		.tarifs_col{
			text-align: left;
		}

		.tarifs{
			display: block;
			text-align: left;
			padding: 10px 0;

			.tarifs_titre{
				display: block;
				margin-bottom: 20px;
			}
			.tarif_prestation,
			.tarif_prix{
				display: inline-block;
				vertical-align: bottom;
			}
			.tarif_prestation{
				width: 72%;
			}
			.tarif_prix{
				width: 25%;
				text-align: right;
			}
		}

		.slider_offres{
			margin-bottom: 20px;
			.owl-stage-outer{
				overflow: hidden;
			}
			.offre{
				background-color: @orange;
				color: @blanc;
				padding: 35px 30px;
				font-style: 16px;
				line-height: 18px;
				margin-bottom: 20px;
				display: inline-block;
				vertical-align: top;
				width: 100%;
				box-sizing: border-box;

				.cta{
					margin-top: 20px;
					margin-bottom: 10px;
				}

				.detail_offre{
					text-align: left;
					.offre_libelle{
						display: block;
						font-family: @font_RH;
						text-transform: uppercase;
						margin-bottom: 12px;
					}
					.offre_valeur{
						margin-bottom: 16px;
						padding-left: 55px;
					}
				}
			}

		}

		.slider_offs_evts{
			margin-bottom: 20px;
			.owl-stage-outer{
				overflow: hidden;
			}

			.slider_offs_evts_item{
				background-color: @orange;
				color: @blanc;
				text-align: center;
				display: inline-block;
				width: 100%;
				box-sizing: border-box;
				vertical-align: top;
				margin-bottom: 20px;

				.slider_offs_evts_visu{
					display: none;
				}

				.slider_offs_evts_texte{
					padding: 40px 10px 0;
					box-sizing: border-box;
				}

				.slider_offs_evts_item_titre{
					text-transform: uppercase;
					font-family: @font_RH;
					font-size: 22px;
					line-height: 24px;
					margin-bottom: 8px;
					display: block;
				}

				.slider_offs_evts_item_date{
					font-size: 15px;
					font-family: @font_PB;
					display: block;
				}

				.slider_offs_evts_item_detail{
					padding: 40px 0;
				}

				.cta{
					margin-bottom: 40px;
				}
			}
		}

		h2{
			font-size: 22px;
			line-height: 26px;
			text-transform: uppercase;
			margin-bottom: 20px;
			display: none;
			strong{
				display: block;
				font-family: @font_PB;
			}
		}

		#salon{
			h2{
				display: block;
			}
			p{
				padding-bottom: 15px;
			}
		}

		.cta-avis{
			display: block;
			background-color: @orange;
			color: @blanc;
			text-transform: uppercase;
			height: 50px;
			line-height: 50px;
			font-size: 18px;
			width: ~'calc(100% - 30px)';
			margin: 20px auto;
			&:before{
				display: inline-block;
				width: 13px;
				height: 17px;
				background-image: url(../img/avis.svg);
				background-size: 13px 17px;
				content: ' ';
				vertical-align: middle;
				margin-right: 12px;
			}
		}
	}

	.nav_ancre{
		display: none;
	}

}
@media screen and (min-width: 768px) {


	.fiche-salon{

		.bandeau_20_pourcent{
			margin-bottom: 10px;
			border-top: solid 20px @blanc;
			padding: 12px;
			font-size: 25px;
			line-height: 30px;
			img{
				height: 40px;
			}
			a{
				background-image: url(../img/cta-plus-blanc.svg);
				width: 27px;
				height: 18px;
				background-size: 27px 18px;
				display: inline-block;
				margin-left: 10px;
			}
		}

		.fiche-salon_top_maps{
			width: 50%;
			box-sizing: border-box;
			padding-left: 15px;
			float: left;
		}

		.fiche-salon_top_info{
			width: 50%;
			box-sizing: border-box;
			float: left;
			padding-bottom: 0;
			padding-top: 30px;
			h1{
				width: 60%;
				padding-bottom: 30px;
				span{
					font-size: 38px;
					padding-top: 14px;
				}
			}

			h1,
			ul{
				margin-left: 20px;
			}

			ul{
				li{
					padding-left: 40px;
					.horaire{
						line-height: 1.3;
					}
				}
			}
		}


		.nav_ancre{
			display: block;
			border-top: solid 1px @orange;
			border-bottom: solid 1px @orange;
			margin: 40px 15px 30px 15px;
			text-align: left;
			ul{
				float: left;
				text-align: center;
				width: ~"calc(100% - 145px)";
				margin-left: 0;
				li{
					display: inline-block;
					line-height: 64px;
					margin: 0 0 0 6px;
					font-size: 13px;
					&:before{
						content: ' ';
						width: 7px;
						height: 7px;
						display: inline-block;
						background-image: url(../img/separateur-ancre.svg);
						vertical-align: middle;
						margin-right: 6px;
						position: relative;
						top: -1px;
						left: -2px;
					}
					&:nth-child(1){
						&:before{
							display: none;
						}
					}
					a{
						color: @noir;
						text-transform: uppercase;
					}
				}
			}

			.cta-avis{
				display: block;
				background-color: @orange;
				text-transform: uppercase;
				height: 64px;
				line-height: 64px;
				font-size: 13px;
				float: right;
				color: transparent;
				overflow: hidden;
				text-indent: -9999px;
				width: 70px;
				text-align: center;
				&:before{
					display: block;
					width: 13px;
					height: 17px;
					background-image: url(../img/avis.svg);
					background-size: 13px 17px;
					content: ' ';
					vertical-align: middle;
					margin: 23px auto 0;
				}
			}
		}

		.fiche-salon_club_fidelite{
			margin-top: 15px;
			padding: 40px 20px;
			p{
				padding-bottom: 30px;
			}
		}

		.fiche-salon_contenu{
			h2{
				display: block;
				font-size: 30px;
				line-height: 32px;
				margin-bottom: 30px;
				strong{
					display: inline;
				}
			}

			.slider-salon{
				position: relative;
				margin-top: 30px;

				.owl-stage-outer{
					overflow: hidden;
					width: 100%;
					margin: 0 auto;
				}

				.owl-thumbs{
					position: absolute;
					right: -82px;
					top: 0;
					button {
					    display: block;
					    margin-right: 0;
					    margin-top: 0;
					    margin-bottom: 20px;
					}
				}

			}

			.fiche-salon_details{
				dt{
					display: none;
				}
				dd{
					display: block !important;
				}
			}

				.slider_offs_evts_outer{
					position: relative;
					max-width: 960px;
					margin: 0 auto;
					overflow: hidden;
				}

				.slider_offs_evts_pagin{
					position: absolute;
					top: 0;
					right: 0;
					z-index: 1;

					.slider_offs_evts_pagin_bg{
						background: @noir;
						width: 108px;
						height: 68px;
						top: -10px;
						right: -10px;
						position: absolute;
						-moz-transform: skewX(18deg) skewY(-4deg);
						-webkit-transform: skewX(18deg) skewY(-4deg);
						-o-transform: skewX(18deg) skewY(-4deg);
						-ms-transform: skewX(18deg) skewY(-4deg);
						transform: skewX(18deg) skewY(-4deg);
					}

					.slider_offs_evts_pagin_prev,
					.slider_offs_evts_pagin_next{
						background-image: url(../img/carousel-fleche-blanche.svg);
						width: 20px;
						height: 20px;
						background-size: 20px 20px;
						display: inline-block;
						z-index: 1;
						position: relative;
						top: 20px;
						right: 16px;
					}
					.slider_offs_evts_pagin_prev{
					}
					.slider_offs_evts_pagin_next{
						transform: rotate(180deg);
						margin-left: 16px;
					}
				}

				.slider_offs_evts{

					.slider_offs_evts_item{
						position: relative;


						.slider_offs_evts_visu{
							display: table-cell;
							vertical-align: middle;
							width: 50%;
							background-color: @noir;

							img{
								display: block;
								margin: 0 auto;
								max-width: 90%;
								max-height: 90%;
								width: auto;
								height: auto;
							}
						}

						.slider_offs_evts_texte{
							display: table-cell;
							width: 50%;
							vertical-align: middle;
							padding-top: 70px;


						}

					}
				}

			#tarifs{
				.tarifs_col{
					text-align: left;
				    /*-webkit-column-count: 2;
				    -moz-column-count: 2;
				    column-count: 2;
				    -webkit-column-gap: 40px;
				    -moz-column-gap: 40px;
				    column-gap: 40px;*/
				}
				.col_left, .col_right{
					width:49%;
					display:inline-block;
					box-sizing: border-box;
					vertical-align: top;
					.asterisk{
						vertical-align: bottom;
					}
				}
				.col_right{
					padding-left:60px;
				}
			}

			#salon{
				padding-left: 30px;
				padding-right: 30px;
			}

			.slider_offres{
				.offre{
					padding: 44px 24px;
					font-size: 16px;
					.offre_part_1,
					.offre_part_2{
						display: inline-block;
						vertical-align: top;
						width: 49%;
					}
					.offre_libelle,
					.offre_valeur{
						display: block;
						float: left;
						margin: 0;
						box-sizing: border-box;
					}
					.offre_libelle{
						width: 35%;
					}
					.offre_valeur{
						width: 65%;
					}
					ul{
						margin-left: 18px;
					}
				}

			}

			.cta-avis{
				display: none;
			}


		}
	}

}
@media screen and (min-width: 1024px) {

	.fiche-salon{

		.bandeau_20_pourcent{
			margin-bottom: 15px;
			border-top: none;
			padding: 12px;
			font-size: 30px;
			line-height: 45px;
			img{
				height: 45px;
			}
			strong{
				display: inline;
			}
			a{
				position: relative;
				top: -2px;
			}
		}

		.fiche-salon_top{
			max-width: 1366px;
			box-sizing: border-box;
			margin: 0 auto;

			h1{
				font-size: 30px;
				width: 100%;
				padding-bottom: 50px;
				span{
					font-size: 42px;
					letter-spacing: 2px;
					padding-top: 20px;
				}
			}
			h1, ul{
				margin-left: 30px;
			}

			.fiche-salon_top_maps{
				width: 55%;
			}

			.fiche-salon_top_info{
				width: 45%;

				ul{
					li{
						padding-left: 40px;
					}
				}
			}
		}

		.fiche-salon_club_fidelite{
			margin-top: 25px;
			p{
				font-size: 30px;
				line-height: 35px;
			}

		}

		.fiche-salon_contenu{

			.slider_offs_evts,
			.slider_offres{
				max-width: 960px;
				margin-left: auto;
				margin-right: auto;

				.offre{
					padding: 44px 36px;
					font-size: 16px;
					.detail_offre{
						.offre_libelle{
							margin-bottom: 20px;
						}
					}
				}
			}
			#salon{
				max-width: 760px;
				margin-left: auto;
				margin-right: auto;
			}
			#tarifs{
				max-width: 920px;
				margin-left: auto;
				margin-right: auto;
			}

			.slider_offs_evts{
				.slider_offs_evts_item{
					.slider_offs_evts_item_titre{
						font-size: 30px;
					}
					.slider_offs_evts_texte{
						padding-left: 30px;
						padding-right: 30px;
					}
					.slider_offs_evts_visu{
						img{
							//width: 90%;
							max-height: none;
						}
					}
				}
			}

		}

		.nav_ancre{
			max-width: 960px;
			margin-left: auto;
			margin-right: auto;
			margin: 60px auto 26px;
			ul{
				//margin-left: 10px;
				li{
					margin: 0 0 0 12px;
					font-size: 14px;
					&:before{
						margin-right: 12px;
					}
				}
			}

			.cta-avis{
				font-size: 14px;
				color: @blanc;
				text-indent: inherit;
				width: auto;
				padding: 0 14px;
				&:before{
					display: inline-block;
					vertical-align: middle;
					margin: 0 14px 0 0;
				}
			}
		}

	}
}
