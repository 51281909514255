.liste-produits{
	h1{
		padding: 25px 0;
		text-transform: uppercase;
		font-size: 26px;
		line-height: 30px;
		font-family: @font_RR;
		text-align: center;
		letter-spacing: 1px;
		strong{
			font-family: @font_RH;
			display: block;
		}
	}


	.bottom_block{
		.bottom_block_locator{
			.para{
				color: transparent;
				line-height: 0;
			}
		}

	}


	.overlay_filtre{
		background-color: rgba(0, 0, 0, 0.25);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		width: 100%;
		height: 100%;

		display: none;
	}

	.flitres_produits{
		text-align: left;
		position: absolute;
		top: 0;
		left: -100%;
		z-index: 999;
		background-color: @gris_clair;
		width: 260px;
		padding: 0 0 25px;
		box-sizing: border-box;
		.titre{
			display: none;
		}
		ul,ol{
			list-style: none;
		}
		dl{
			dt{
				font-family: @font_RH;
				text-transform: uppercase;
				font-size: 16px;
				line-height: 50px;
				position: relative;
				cursor: pointer;
				background-color: @gris_clair_3;
				padding: 0 25px;
				&:after{
					content: '';
					display: block;
					width: 6px;
					height: 10px;
					background-size: 6px 10px;
					background-image: url(../img/pagination.svg);
					.mixin-rotate(-90deg);
					position: absolute;
					right: 20px;
					top: 50%;
					margin-top: -4px;
					.mixin-transition(all 250ms linear);
				}
				&.open{
					&:after{
						.mixin-rotate(90deg);
					}
				}
			}
			dd{
				font-size: 14px;
				ul{
					padding: 15px 0;
					li{
						display: block;
						padding: 5px 25px;
						input[type="checkbox"]{
							border: solid 1px @noir;
							width: 9px;
							height: 9px;
							.mixin-border-radius(2px);
							display: inline-block;
							vertical-align: middle;
							&:checked{
								background-color: @noir;
							}
						}
						label{
							display: inline-block;
							vertical-align: middle;
						}
					}
				}
			}
		}
		.btn{
			background-color: @orange;
			display: inline-block;
			padding: 10px;
			margin-right: auto;
			margin-left: auto;
			width: 190px;
			text-align: center;
			margin-bottom: 15px;
			margin-top: 15px;
			.cta{
				width: 135px;
				text-align: center;
				display: inline-block;
			}
			&:hover{
				.cta{
					padding-left: 0;
					&:after{
						//right: 0;
						.mixin-rotate(0deg);
					}

					&:before{
						right: 10px;
						.mixin-rotate(180deg);
					}
				}
			}
		}
	}

	.liste_produits{
		text-align: center;
		max-width: 960px;
		.numb{
			font-size: 12px;
		}
		.row_filtre_cta{
			.cta{
				font-size: 16px;
			}
		}
		.liste_produits_item{
			display: inline-block;
			color: @noir;
			width: 225px;
			margin: 15px auto;
			vertical-align: top;
			img{
				max-width: 100%;
				height: auto;
			}
			.liste_produits_titre{
				text-transform: uppercase;
				display: block;
				padding-bottom: 15px;
				font-size: 16px;
				padding-top: 8px;
				& > span:last-child,
				strong{
					font-family: @font_RH;
				}
			}
		}
	}

	.pagination_actu{
		padding: 25px 0 45px;
		text-align: center;
		.page-numbers{
			display: inline-block;
			margin: 0 10px;
			font-family: @font_RR;
			color: @noir;
			font-size: 16px;
			vertical-align: top;
			line-height: 18px;
			&.next,
			&.prev{
				width: 10px;
				height: 18px;
				background-image: url(../img/pagination.svg);
				background-repeat: no-repeat;
				background-size: 10px 18px;
				position: relative;
				top: 2px;
				margin: 0 30px;
			}
			&.prev{
				.mixin-rotate(180deg);
			}
		}
		span{
			&.page-numbers{
				font-family: @font_RH;
				border-bottom: solid 2px @noir;
			}
		}
	}



}

@media screen and (min-width: 640px) {
	.liste-produits{
		h1{
			padding: 35px 0 25px;
			font-size: 38px;
			line-height: 45px;
			strong{
				display: inline;
			}
		}

		.liste_produits{
			.row_filtre_cta{
				padding-left: 40px;
			}
			text-align: left;
			width: 690px;
			margin-left: auto;
			margin-right: auto;
			.liste_produits_item{
				text-align: center;
				margin: 25px auto;
			}

		}

	}
}


@media screen and (min-width: 1024px) {
	.liste-produits{
		h1{
			padding: 40px 0 45px;
			font-size: 45px;
			line-height: 55px;
		}

		.flitres_produits{
			position: inherit;
			top: 0;
			left: 0;
			z-index: 1;
			display: inline-block;
			vertical-align: top;
			.titre{
				display: block;
				font-family: @font_RH;
				text-transform: uppercase;
				font-size: 15px;
				line-height: 50px;
				position: relative;
				padding: 0;
				background-color: @blanc;
			}
			dl{
				dt{
					font-size: 15px;
				}
			}
		}

		.liste_produits{
			.numb{
				font-size: 12px;
				display: block;
				line-height: 50px;
			}
			display: inline-block;
			vertical-align: top;
			padding-left: 25px;
			//width: 850px;
			width: ~'calc(100% - 290px)';
			max-width: 850px;
			.row_filtre_cta{
				padding-left: 0;
				&:nth-child(1){
					display: none;
				}
			}
			.liste_produits_item{
				text-align: center;
				margin: 25px;
			}
		}


		.bottom_block{
			padding: 50px 0;
			.bottom_block_locator{
				border-left: solid 10px @blanc;
				border-right: 0;
				font-size: 25px;
				line-height: 35px;
				padding-top: 55px;
			}

		}


	}
}
