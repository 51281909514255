#header{

	.animated {
	  	animation-duration: 0.5s;
	}

	height: 50px;
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	text-align: left;
	z-index: 900;
	background-color: @blanc;

	.header_logo{
		display: block;
		height: 32px;
		width: auto;
		margin-top: 8px;
		vertical-align: middle;
		margin-left: 22%;
	}

	.header_burger{
		background-size: 25px 14px;
		background-repeat: no-repeat;
		background-position: 0 0;
		width: 25px;
		height: 14px;
		display: block;
		background-color: transparent;
		margin: 18px 35px 0 12px;
		float: left;
		background-image: url(../img/header-burger.png);

		position: absolute;
		top: 0;
		left: 0;
	}

	.header_trouver_salon{
		float: right;
		color: @blanc;
		text-decoration: none;
		display: block;
		background-color: @orange;
		font-family: @font_RB;
		font-size: 10px;
		line-height: 13px;
		padding: 12px 6px;
		text-transform: uppercase;
		text-align: center;
		width: 95px;
		box-sizing: border-box;

		position: absolute;
		top: 0;
		right: 0;
	}


}
.overlay_menu,
.overlay_menu_desktop{
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: @noir;
	opacity: 0.25;
	z-index: -1;
	display: none;
}

@media screen and (min-width: 375px){
	#header{
		.header_logo{
			margin-left: 26%;
		}
	}
}

@media screen and (min-width: 413px){
	#header{
		.header_logo{
			margin-left: 29%;
		}
	}
}

@media screen and (min-width: 768px) {

	#header{
		height: 90px;


		.header_burger{
			background-size: 38px 20px;
			width: 38px;
			height: 20px;
			margin: 0;
			float: none;

			position: absolute;
			left: 40px;
			top: 35px;
		}

		.header_logo{
			display: block;
			height: auto;
			width: 210px;
			margin-top: 18px;
			margin-left: auto;
			margin-right: auto;
		}

		.header_trouver_salon{
			font-size: 12px;
			line-height: 16px;
			padding: 14px 12px;
			text-align: center;
			width: 120px;
			position: absolute;
			top: 0;
			right: 0;

			.picto{
				background-image: url(../img/locator.svg);
				background-repeat: no-repeat;
				background-size: 15px 22px;
				width: 15px;
				height: 22px;
				display: block;
				margin: 0 auto 8px;
			}
		}
	}

}


@media screen and (min-width: 1024px) {

	#header{
		height: 98px;

		.header_logo{
			height: 44px;
			margin-top: 25px;
			margin-left: 8px;

			position: relative;
			top: 0;
			left: 0;
			display: inline-block;
			vertical-align: middle;
		}

		.header_trouver_salon{
			padding: 18px 12px;
			.mixin-transition(background-color 125ms linear);
			&:hover{
				background-color: @noir;
			}
		}

		.header_burger{
			display: none;
		}

	}
	.overlay_menu_desktop{
		z-index: 100;
	}

}

@media screen and (min-width: 1200px) {

	#header{
		width: 1200px;
		left: 50%;
		margin-left: -600px;
		overflow: visible;

		.header_logo{
			height: auto;
			width: 210px;
		}

		&:before{
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 98px;
			background-color: @blanc;
			z-index: -1;
			display: block;
			content: ' ';
		}

	}

}
