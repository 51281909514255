.recherche-salon{

	.tetiere_recherche_salon{
		background: @noir url(../img/tetiere-recherche-salon.jpg) no-repeat center;
		background-size: cover;
		color: #FFFFFF;
		padding: 40px 20px;

		&.tetiere_recherche_salon--more-padding{
			h1{
				margin-top: 36px;
			}
		}

		h1{
			font-size: 30px;
			text-transform: uppercase;
			line-height: 1.2;
			margin-bottom: 36px;
			span{
				display: block;
			}
		}

		.recherche_salon_geoloc,
		.recherche_salon_form{
			max-width: 280px;
			margin: 0 auto;
			display: inline-block;
			width: 100%;
			vertical-align: top;
		}

		input[type="text"], 
		.input_look span:nth-child(1){
			line-height: 42px;
			height: 42px;
			background-color: @blanc;
			width: ~"calc(100% - 45px)";
			font-size: 12px;
			color: @noir;
			font-family: @font_RR;
			float: left;
			border: none;
			padding: 0 25px;
			box-sizing: border-box;
			letter-spacing: 1px;
			text-align: left;
			&::-webkit-input-placeholder {
			  color: @noir;
			}
			&::-moz-placeholder { 
			  color: @noir;
			}
			&:-ms-input-placeholder {
			  color: @noir;
			}
			&:-moz-placeholder {
			  color: @noir;
			}
		}
		input[type="submit"],
		button,
		.input_look span:nth-child(2){
			line-height: 42px;
			height: 42px;
			background-color: @blanc;
			width: 45px;
			float: left;
			font-size: 12px;
			color: @noir;
			font-family: @font_RB;
			border: none;
			position: relative;
			&:before{
				content: ' ';
				width: 0;
				height: 20px;
				display: inline-block;
				border-style: solid;
				border-width: 1px;
				border-color: @noir;
				.mixin-rotate();
				position: absolute;
				left: 3px;
				top: 9px;
			}
		}

		.input_look{
			display: block;
			margin-bottom: 12px;
			&.bg_orange{
				span{
					background: @orange;
					color: #FFFFFF;
					&:before{
						border-color: #FFFFFF;
					}
				}
			}
		}

	}

	h2{
		font-size: 24px;
		text-transform: uppercase;
		margin: 30px 20px 10px;
		line-height: 1.1;
		span{display: block;}
	}

	/* a */
	.liste_departements_villes{
		text-align: left;
		padding: 20px;
		h3{
			text-transform: uppercase;
			font-family: 'Raleway-Black';
			font-size: 16px;
		}
		ul{
			list-style: none;
			margin: 16px 0 30px;

			&.nb_column_2{
				-webkit-column-count: 2;
			    -moz-column-count: 2;
			    column-count: 2;
			}
			li{
				padding: 3px 0;
				a{
					color: @noir;
					&:hover{
						text-decoration: underline;
					}
				}
			}
		}
	}

	/* b */

	.liste_salon_carte_outer{
		padding: 12px;

		.liste_salon_carte{
			iframe{
				width: 100%;
				display: block;
				height: 240px;				
			}
		}

		.liste_salon_liste{
			height: 650px;
			overflow: auto;
			ul{
				list-style: none;
				li{
					text-align: left;
					border-bottom: 1px solid #F1F1F1;
					padding-bottom: 10px;
					margin: 20px 0 30px;
					a{
						color: @noir;
						&.cta{
							margin-top: 15px;
						}
						&.orange{
							color: @orange;
						}
						img{
							display: inline-block;
							vertical-align: middle;
							max-width: 50px;
							max-height: 24px;

							&.icon-salon-club{
								max-width: 34px;
								max-height: 15px;
							}
							&.icon-salon-jeudi{
								max-width: 27px;
								max-height: 27px;
							}
							&.icon-salon-new{
								max-width: 48px;
								max-height: 15px;
							}
						}
						span{
							font-family: 'PlayfairDisplay-Bold';
							display: block;
							margin-top: 10px;
							text-transform: uppercase;
						}
						p{
							padding: 10px 0;
						}
					}
				}
			}
		}
	}	

	/* d */

	.liste_salon{
		padding: 0 20px;
		max-width: 1280px;
		margin: 0 auto;
		ul{
			text-align: left;
			list-style: none;
			margin: 24px 0 36px;
			li{
				margin-bottom: 24px;
				display: inline-block;
				width: 49%;
				min-width: 250px;
				vertical-align: top;
				a{
					color: @noir;
					display: inline-block;
					text-align: left;
					span{
						font-family: 'PlayfairDisplay-Bold';
					}
					p{
						margin-top: 10px;
					}
				}
			}
		}
	}

}

/* only mobile */
@media screen and (max-width: 640px) {
	.recherche-salon{

		/* a */
		.liste_departements_villes{
			ul{
				&.nb_column_2_mobile{
					-webkit-column-count: 2;
				    -moz-column-count: 2;
				    column-count: 2;
				}
			}
		}
	}
}



@media screen and (min-width: 640px) {
	.recherche-salon{

		.tetiere_recherche_salon{

			h1{
				font-size: 40px;
				margin-bottom: 50px;
				span:nth-child(1),
				span:nth-child(2){
					display: inline-block;
				}
			}

			.recherche_salon_geoloc{
				margin-right: 24px;
			}
			.recherche_salon_form{
				margin-left: 24px;	
			}

		}

		h2{
			font-size: 32px;
			margin: 46px 20px 28px;
			span{display: inline;}
		}

		/* a */
		.liste_departements_villes{
			padding-left: 10%;
			padding-right: 10%;
			max-width: 1200px;
		    margin: 0 auto;
		    position: relative;
		    left: 6%;

			.w66_mintablet,
			.w33_mintablet{
				display: inline-block;
				vertical-align: top;
			}
			.w66_mintablet{
				width: 66%;
			}
			.w33_mintablet{
				width: 33%;
			}

		}

	}
}

@media screen and (min-width: 768px) {

	.recherche-salon{

		/* b */
		.liste_salon_carte_outer{
			direction: rtl;
			padding: 36px;
			.liste_salon_carte{
				direction: ltr;
				display: inline-block;
				vertical-align: top;
				width: 50%;
				iframe{
					height: 650px;
				}
			}
			.liste_salon_liste{
				direction: ltr;
				display: inline-block;
				vertical-align: top;
				width: 50%;
				max-width: 400px;
				ul{
					width: 82%;
					li{
						font-size: 110%;
					}
				}
			}
		}

		/* d */
		.liste_salon{
			ul{
				li{
					min-width: 0;
					width: 32.5%;
					text-align: left;
					box-sizing: border-box;
					padding-left: 10%;
				}
			}
		}
	}
}


@media screen and (min-width: 640px) and (max-width: 1023px) {
	.recherche-salon{
		
	}
}


@media screen and (min-width: 1024px) {
	.recherche-salon{
		.tetiere_recherche_salon{
			h1{
				font-size: 44px;
			}
		}
	}
}

@media screen and (min-width: 1200px) {
	.recherche-salon{
		.liste_departements_villes{
		    left: 9%;
		}
	}
}
@media screen and (min-width: 1400px) {
	.recherche-salon{
		.liste_departements_villes{
		    left: 10%;
		}
	}
}
@media screen and (min-width: 1680px) {
	.recherche-salon{
		.liste_departements_villes{
		    left: 6%;
		}
	}
}