.recrutement{

	&__tetiere{
		background: @noir url(../img/tetiere-recrutement.jpg) no-repeat center;
		background-size: cover;
		color: #FFFFFF;
		padding: 40px 20px;

		h1{
			font-size: 30px;
			text-transform: uppercase;
			line-height: 1.2;
			margin-bottom: 36px;
			font-family: "Raleway-Black";
		}
		span{
			display: inline-block;
		}

	}

	.bloc_newsletter{
		&.full_width{
			margin-top: 0;
		}
	}


	&__offres{
		padding: 16px;

		h2{
			font-size: 32px;
			text-transform: uppercase;
			margin: 24px 0;
			text-align: center;
		}

		.custom-select{
			position: relative;
			margin: 20px auto;
			max-width: 320px;

			&:after{
			    content: "";
			    display: block;
			    position: absolute;
			    top: 50%;
			    right: 16px;
			    margin-top: -0.4em;
			    width: 0.4em;
			    height: 0.4em;
			    border-right: 0.15em solid #FFFFFF;
			    border-top: 0.15em solid #FFFFFF;
			    transform: rotate(135deg);
			}
			select{
				width: 100%;
				margin: 0;
				padding: 8px 10px;
				border: 0;
				box-sizing: border-box;
				font-family: "Raleway-Regular";
				color: #FFFFFF;

				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				padding-right: 40px;
				background: #e67a2a;
			}
		}

		.cta--big{
			display: block;
			background: #F6F6F6;
			padding-top: 24px;
			padding-bottom: 24px;
			margin: 16px 0;
			text-align: center;

			.cta{ 
				font-size: 14px;
			}

			&:hover{
				.cta{
					&:after{
						//right: 0;
						.mixin-rotate(0deg);
					}

					&:before{
						right: 10px;
						.mixin-rotate(180deg);
					}
					
				}
			}
			
		}
		.cta--grey{
			color: #FFFFFF;
			background: #565656;
		}


		&__liste{
			margin-top: 36px;
			ul{
				list-style: none;
				margin-top: 24px;
				li{
					border: 1px solid #e67a2a;
					padding: 20px 10px;
					margin-bottom: 20px;
				}
			}

			&__ref{
				margin: 6px 0 16px;
				font-family: "Raleway-Bold";
			}

			&__intitule{
				color: #e67a2a;
				text-transform: uppercase;
				font-family: "Raleway-Black";
				font-size: 16px;
				margin-top: 6px;
			}
			p{
				margin: 16px 0;
				line-height: 1.2;
				height: 50px;
			    overflow: hidden;
			    transition: 1s;
			    &.expanded{
			    	height: auto;
			    	transition: 1s;
			    }
			}

			&__details{
				color: @noir;
				margin: 16px 0 24px;
				display: block;
				span{
					display: inline-block;
					vertical-align: middle;
					width: 22px;
					height: 22px;
					line-height: 22px;
					color: #FFFFFF;
					background: #e67a2a;
					border-radius: 50%;
					text-align: center;
					margin-right: 6px;
					position: relative;
					&:before{
						content: '\f067';
						position: absolute;
						font-family: @font_A;
						top: 0;
						left: 6px;
						font-weight: 100;
						font-size: 14px;
					}

					&.expanded{
						&:before{
							content: '-';
							font-family: Arial;
							top: -3px;
							left: 6px;
							font-size: 34px;
						}
					}
				}
				&:hover{
					text-decoration: underline;
				}
			}

			.cta{
				background: #e67a2a;
				color: #FFFFFF;
				&:after{
					border-color: #FFFFFF;
				}
			}

		}


		&__pagination{
			margin: 30px 0;
			ul{
				list-style: none;
				text-align: center;
				li{
					display: inline-block;
					a{
						color: @noir;
						font-size: 16px;
						padding: 10px;
					}
					&.active{
						a{
							font-family: "Raleway-Bold";
							text-decoration: underline;
						}
					}
				}
			}
		}

	}


}

#alert-content-wrapper{

	background-color: rgba(0,0,0,0.5) !important;

	&.container-alert{

		.container-form{
			background-color: @blanc;
			color: @noir;

			.close-popin{
				top: 0;
				right: 0;
				background-image: url(../img/close-popup.svg);
				width: 57px;
				height: 44px;

				&:hover{
					background-image: url(../img/close-popup-hover.svg);
				}
			}

			.postuler__alerte__titre{
				color: @noir;
			}

			.postuler__forms{
				min-height: inherit;

				.btn_unsubscribe{
					border: none;
					background-color: transparent;
					margin-top: 30px;
					text-transform: uppercase;
					display: inline-block;
					color: @noir;
					font-size: 12px;
					text-decoration: underline;
					cursor: pointer;
					&:hover{
						text-decoration: none;
					}
				}
			}

			.postuler__table-form{
				width: 100%;

				td{
					width: 100% !important;
					padding-right: 0 !important;
					text-align: center;
				}
			}


			.custom-select{
				&:after{
					content: "";
					display: block;
					position: absolute;
					top: 50%;
					right: 16px;
					margin-top: -0.4em;
					width: 0.4em;
					height: 0.4em;
					border-right: 0.15em solid #e67a2a;
					border-top: 0.15em solid #e67a2a;
					transform: rotate(135deg);
				}				
			}		


			.form-alert{

				.cta{
					text-transform: uppercase;
					text-decoration: none;
					font-family: @font_RB;
					font-size: 12px;
					display: inline-block;
					line-height: 18px;
					height: 18px;
					padding-right: 34px;
					position: relative;
					overflow: hidden;
					letter-spacing: 1px;
					color: @noir;
					cursor: pointer;
					background-color: transparent;
					margin-top: 30px;
					.mixin-transition(all 125ms linear);

					.hide_mobile{
						display: none;
					}

					.hide_mobile_ib{
						display: inline-none;
					}

					&:before{
						content: ' ';
						width: 12px;
						height: 12px;
						background-image: url(../img/sprite-cta.png);
						background-repeat: no-repeat;
						background-size: 28px 75px;
						position: absolute;
						right: 0;
						top: 3px;
						.mixin-transition(all 125ms linear);
					}


					&:after{
						content: ' ';
						width: 0;
						height: 30px;
						display: inline-block;
						border-style: solid;
						border-width: 1px;
						border-color: @noir;
						.mixin-rotate();
						position: absolute;
						right: 24px;
						top: 0;
						.mixin-transition(all 125ms linear);
					}

					&:hover{
						padding-left: 0;
						&:after{
							//right: 0;
							.mixin-rotate(0deg);
						}

						&:before{
							right: 10px;
							.mixin-rotate(180deg);
						}
					}

					&.border{
						border-style: solid;
						border-width: 2px;
						.mixin-border-radius();
						line-height: 48px;
						height: 48px;
						padding-left: 54px;
						padding-right: 84px;
						font-size: 14px;
						&:after{
							height: 50px;
							right: 44px;
							top: -5px;
						}
						&:before{
							right: 18px;
							top: 17px;
						}
					}

					&.blanc{
						color: @blanc;
						border-color: @blanc;
						background-position: -2px 0;
						&:after{
							border-color: @blanc;
						}
						&:before{
							background-position: -2px 0;
						}
					}
					&.orange{
						color: @orange;
						border-color: @orange;
						&:after{
							border-color: @orange;
						}
						&:before{
							background-position: 0px -28px;
						}
					}
					&.parme{
						color: @parme;
						border-color: @parme;
						&:after{
							border-color: @parme;
						}
						&:before{
							background-position: -14px -28px;
						}
					}
					&.jaune{
						color: @jaune;
						border-color: @jaune;
						&:after{
							border-color: @jaune;
						}
						&:before{
							background-position: -0px -14px;
						}
					}
					&.vert{
						color: @vert;
						border-color: @vert;
						&:after{
							border-color: @vert;
						}
						&:before{
							background-position: -14px -42px;
						}
					}
					&.rose{
						color: @rose;
						border-color: @rose;
						&:after{
							border-color: @rose;
						}
						&:before{
							background-position: -2px -42px;
						}
					}
					&.bleu{
						color: @bleu;
						border-color: @bleu;
						&:after{
							border-color: @bleu;
						}
						&:before{
							background-position: -14px 0;
						}
					}
					&.noir{
						color: @noir;
						border-color: @noir;
						&:after{
							border-color: @noir;
						}
						&:before{
							background-position: -14px -14px;
						}
					}
					&.position{
						&:before{
							background-position: -2px -56px;
							width: 12px;
							height: 17px;
							    top: 11px;
						}
					}
				}

	            input[type="text"],
	            select,
	            textarea{
	                width: 100%;
	                margin: 0;
	                padding: 8px 10px;
	                border: 1px solid #000000;
	                box-sizing: border-box;
	                font-family: "Raleway-Regular";
	                &.short_champs {
	                    width:60%;
	                }
	            }

			}
		}

	}

}

/* only mobile */
@media screen and (max-width: 640px) {
	.recrutement{

		.bloc_newsletter{
			&.full_width{
				margin-top: 0;
			}
		}

	}
}


@media screen and (min-width: 640px) {
	.recrutement{

		.bloc_newsletter{
			&.full_width{
				margin-top: 0;
			}
		}

		&__tetiere{
			padding: 80px 20px;
			h1{
				font-size: 44px;
			}
			span{
				max-width: 860px;
				padding: 0 10%;
			}
		}


		&__offres{
			padding: 30px 8%;
			max-width: 860px;
			margin: 0 auto;
			text-align: left;

			h2{
				margin: 24px 0 36px;
			}

			&__left,
			&__right{
				display: inline-block;
				vertical-align: top;
				width: 50%;
				box-sizing: border-box;
				margin-bottom: 24px;
			}
			&__left{
				padding-right: 2%;
			}
			&__right{
				padding-left: 2%;
			}

			&__liste{

				ul{
					li{
						padding: 24px;
						position: relative;
					}
				}

				&__ref{
					margin: 0;
					position: absolute;
					right: 20px;
					top: 20px;
				}

				p{
					width: 72%;
					margin-bottom: 30px;
				}

				.cta{
					margin: 0;
					position: absolute;
					right: 20px;
					bottom: 24px;
				}

			}

		}


	}
}

@media screen and (min-width: 768px) {
	.recrutement{

		.bloc_newsletter{
			&.full_width{
				margin-top: 0;
			}
		}

	}
}


@media screen and (min-width: 640px) and (max-width: 1023px) {
	.recrutement{

		.bloc_newsletter{
			&.full_width{
				margin-top: 0;
			}
		}
		
	}
}


@media screen and (min-width: 1024px) {
	.recrutement{

		.bloc_newsletter{
			&.full_width{
				margin-top: 0;
			}
		}
		
	}
}