
/* common cta modifier - (à déplacer) */
.cta{
	&.lcase{
		text-transform: none;
		font-family: "Raleway-Regular";
		font-size: 14px;
	}
	&.plus{
		padding-right: 40px;
		&:before{
			content: "+";
			font-size: 36px;
			background: none;
			right: 6px;
			top: 1px;

		}
		&:hover{
			&:before{
				top: 5px;
			}
		}
	}
	&.bg-orange{
		background: @orange;
		color: #FFFFFF;
		&:after{
			border-color: #FFFFFF;
		}
	}	
}


/* specific for page collection-details */

.collection-details{

	.titre{
		strong{
			display: inline-block;
		}
	}

	.fiche-collection{
		padding: 16px 0;

		.retour{
			color: @gris;
			font-family: "Raleway-Light";
			font-size: 13px;
		}
		h1{
			font-size: 26px;
			text-transform: uppercase;
			padding: 10px 10px 16px;
			line-height: 1.2;
			strong{
				font-family: 'Raleway-Black';
			}
		}

		.slider-fiche-collection{
			position: relative;
			overflow: hidden;
			.slider_pagin{
				position: absolute;
				top: 0;
				right: 0;
				z-index: 1;
				.slider_pagin_bg{
					background: @orange;
					width: 90px;
					height: 50px;
					top: -10px;
					right: -10px;
					position: absolute;
					-moz-transform: skewX(18deg) skewY(-4deg);
					-webkit-transform: skewX(18deg) skewY(-4deg);
					-o-transform: skewX(18deg) skewY(-4deg);
					-ms-transform: skewX(18deg) skewY(-4deg);
					transform: skewX(18deg) skewY(-4deg);
					transition : 0.3s;
				}
				&:hover{
					.slider_pagin_bg{
						-moz-transform: skewX(24deg) skewY(-8deg);
						-webkit-transform: skewX(24deg) skewY(-8deg);
						-o-transform: skewX(24deg) skewY(-8deg);
						-ms-transform: skewX(24deg) skewY(-8deg);
						transform: skewX(24deg) skewY(-8deg);
						transition : 0.3s;
					}
				}

				.slider_pagin_prev,
				.slider_pagin_next{
					background-image: url(../img/carousel-fleche-blanche.svg);
					width: 20px;
					height: 20px;
					background-size: 20px 20px;
					display: inline-block;
					z-index: 1;
					position: relative;
					top: 10px;
					right: 10px;
				}
				.slider_pagin_next{
					transform: rotate(180deg);
					margin-left: 10px;
				}
			}

			img{
				max-width: 100%;
				display: block;
			}
			.credit_img{
				color: @noir;
				font-size: 10px;
				font-weight: bold;
				font-family: Arial;
				position: absolute;
				.mixin-rotate(@deg : 270deg);
				margin-left: -64px;
    			margin-top: 70px;
    			z-index: 10;
			}
			.social{
				position: absolute;
				bottom: 0;
				right: 0;
				.social-fb,
				.social-tw,
				.social-pin{
					display: block;
					width: 42px;
					height: 42px;
					color: #FFFFFF;
					font-size: 10px;
					padding-top: 23px;
					box-sizing: border-box;
				}
				.social-fb{
					background: #3b5998 url(../img/social/picto-fb.png) no-repeat center 8px;
				}
				.social-tw{
					background: #1da1f2 url(../img/social/picto-tw.png) no-repeat center 8px;
				}
				.social-pin{
					background: #b00616 url(../img/social/picto-pin.png) no-repeat center 8px;
				}
			}
		}

		.infos-fiche-collection{
			padding: 0 24px;

			p{
				padding: 16px 0;
			}
			h2{
				font-size: 18px;
				text-transform: uppercase;
				padding: 16px 0 4px;
				font-family: 'Raleway-Black';
			}

			.produit-assoc{
				padding: 16px 0 24px;
				&:before{
					content: "";
					display: block;
					width: 60px;
					height: 1px;
					background: @noir;
					margin: 10px auto;
				}
				.img-produit-assoc{
					max-height: 150px;
					display: block;
					margin: 10px auto;
				}
			}
			.cta:before{
				width: 14px;
			    height: 17px;
			    background-position: 0px -56px;
			}
			.cta.border:before {
			    top: 10px;
			}
			.cta:hover:before {
			    .mixin-rotate(@deg : 25deg);
			}	
		}

	}
	.bg_mag_gris{
		background: @gris_clair;
		padding: 16px 0;
		margin: 30px 0;
	}

}

@media screen and (min-width: 640px) {
	.collection-details{

		.fiche-collection{

			h1{
				font-size: 38px;
				padding: 16px 16px 20px;
			}

			.slider-fiche-collection{

				.slider_pagin{
					.slider_pagin_bg{
						width: 108px;
						height: 68px;
					}
					.slider_pagin_prev,
					.slider_pagin_next{
						top: 20px;
						right: 16px;
					}
					.slider_pagin_next{
						margin-left: 16px;
					}
				}

			}

			.infos-fiche-collection{
				padding: 0 5%;

				p{
					padding: 26px 0;
					text-align: left;
					max-width: 450px;
				}

				.technique-assoc,
				.produit-assoc{
					p{
						padding: 20px 0;
						max-width: 380px;
					}

				}

				.technique-assoc{
					text-align: left;
				}
				.produit-assoc{
					padding: 0;

					&:before{
						display: none;
					}
				}
			}
		}		
	}
}


@media screen and (min-width: 640px) and (max-width: 1023px) {
	.collection-details{

		.fiche-collection{
			margin: 0 16px;

			.infos-fiche-collection{

				.technique-assoc,
				.produit-assoc{
					display: inline-block;
					width: 50%;
					vertical-align: top;
					height: 250px;
					position: relative;
					margin-bottom: 28px;

					.cta-outer{
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
					}
				}

				.technique-assoc{
					padding-right: 12%;
					box-sizing: border-box;
				}

			}

		}

	}

}

@media screen and (min-width: 1024px) {
	.collection-details{

		.fiche-collection{
			padding-bottom: 46px;

			.slider-fiche-collection{
				display: inline-block;
				width: 56%;
				vertical-align: top;
				max-width: 746px;
			}

			.infos-fiche-collection{
				display: inline-block;
				width: 38%;
				padding: 0 3%;
				vertical-align: top;
				text-align: left;

				h1{
					font-size: 42px;
					padding: 20px 0 0;
					margin-bottom: -10px;
					strong{
						display: block;
					}
				}

				.produit-assoc{
					margin: 20px 0;

					.img-produit-assoc{
						margin: 10px 0;
					}
				}
			}

		}
		.bg_mag_gris{
			p.sous-titre {
			    width: 540px;
			}
		}	
	}
}
