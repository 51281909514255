@blanc : #FFFFFF;
@noir : #282828;
@orange : #e67a2a;
@parme : #7d74a5;
@jaune : #ffcc00;
@vert : #08d2a5;
@rose : #e04664;
@bleu : #52ccda;
@gris : #969696;
@gris_clair : #F6F6F6;
@gris_clair_2 : #3e3e3e;
@gris_clair_3 : #f2f2f2;

@font_RT : 'Raleway-Thin';
@font_RL : 'Raleway-Light';
@font_RR : 'Raleway-Regular';
@font_RM : 'Raleway-Medium';
@font_RB : 'Raleway-Bold';
@font_RH : 'Raleway-Black';
@font_PB : 'PlayfairDisplay-Bold';
@font_A : 'FontAwesome';


/* ROTATION */
.mixin-rotate(@deg : 25deg){
	-ms-transform: rotate(@deg);
	-webkit-transform: rotate(@deg);
	-moz-transform: rotate(@deg);
	transform: rotate(@deg);
}
/* BORDER RADIUS */
.mixin-border-radius(@radius : 50px){
	-ms-border-radius: @radius;
	-webkit-border-radius: @radius;
	-moz-border-radius: @radius;
	border-radius: @radius;
}
/* GRAYSCALE */
.mixin-grayscale(@grayscale : 100%){
	filter: grayscale(@grayscale);
	-webkit-filter: grayscale(@grayscale);
	-moz-filter: grayscale(@grayscale);
	-ms-filter: grayscale(@grayscale);
	-o-filter: grayscale(@grayscale);
}
/* TRANSITION */
.mixin-transition(@value){
	-ms-transition: @value;
	-webkit-transition: @value;
	-moz-transition: @value;
	transition: @value;
}
/* SCALE */
.mixin-scale(@value){
	-ms-transform: scale(@value);
	-webkit-transform: scale(@value);
	-moz-transform: scale(@value);
	transform: scale(@value);
}