footer{
	width: 100%;
	border-top: solid 4px @noir;

/*	.footer_newsletter{
		background-color: @orange;
		color: @blanc;
		font-family: @font_RH;
		text-transform: uppercase;
		padding: 17px;

		.footer_newsletter_form{
			background-color: @blanc;
			width: 100%;
			margin-top: 17px;

			input[type="text"]{
				line-height: 42px;
				height: 42px;
				background-color: @blanc;
				width: ~"calc(100% - 45px)";
				font-size: 12px;
				color: @noir;
				font-family: @font_RR;
				float: left;
				border: none;
				padding: 0 20px;
				box-sizing: border-box;
				letter-spacing: 1px;
				&::-webkit-input-placeholder {
				  color: @noir;
				}
				&::-moz-placeholder { 
				  color: @noir;
				}
				&:-ms-input-placeholder {
				  color: @noir;
				}
				&:-moz-placeholder {
				  color: @noir;
				}
			}
			input[type="submit"],
			button{
				line-height: 42px;
				height: 42px;
				background-color: @blanc;
				width: 45px;
				float: left;
				font-size: 12px;
				color: @noir;
				font-family: @font_RB;
				border: none;
				position: relative;
				&:before{
					content: ' ';
					width: 0;
					height: 15px;
					display: inline-block;
					border-style: solid;
					border-width: 1px;
					border-color: @noir;
					.mixin-rotate();
					position: absolute;
					left: 2px;
					top: 12px;
				}
			}
		}

	}*/

	.footer_menus_colonne{
		display: none;
	}

	.menu-menu-header-container{
		display: none;
	}

	.footer_assurances{
		display: none;
	}

	.footer_menus{
		text-align: center;
		font-size: 12px;
		padding: 15px;
	}
	
}

@media screen and (min-width: 768px) {

	footer{
		border-top: none;
		.icon-footer{
			display: inline-block;
			background-image: url(../img/sprite-footer.png);
			background-repeat: no-repeat;
			background-size: 43px 173px;
			overflow: hidden;
			text-indent: -9999px;
			text-align: left;

			&.footer-rdv{
				background-position: -2px 0;
				width: 39px;
				height: 39px;
			}
			&.footer-salon{
				background-position: -2px -41px;
				width: 31px;
				height: 41px;
			}
			&.footer-expert{
				background-position: -2px -84px;
				width: 30px;
				height: 43px;
			}
			&.footer-produits{
				background-position: -2px -129px;
				width: 20px;
				height: 42px;
			}
		}

		.footer_assurances{
			display: block;
			background-color: @noir;
			color: @blanc;
			padding: 30px 0;

			.assurances_assurance{
				display: inline-block;
				vertical-align: middle;
				margin-left: 30px;

				.icon-footer{
					vertical-align: middle;
				}

				span{
					display: inline-block;
					vertical-align: middle;
					text-align: left;
					text-transform: uppercase;
					padding-left: 10px;
					strong{
						display: block;
						font-family: @font_RH;
					}
				}
			}
		}

		/*.footer_newsletter{

			.footer_newsletter_form{
				width: 286px;
				margin-top: 0;
				display: inline-block;
				vertical-align: middle;
				margin-left: 15px;
			}

		}*/

		.footer_menus_colonne{
			display: inline-block;
			vertical-align: top;
			box-sizing: border-box;
			text-align: left;
			padding-bottom: 25px;
			&.colonne-1,
			&.colonne-2,
			&.colonne-3{
				width: 20%;
			}
			&.colonne-4{
				width: 38%;
				-webkit-column-count: 2;
				-moz-column-count: 2;
				-ms-column-count: 2;
				column-count: 2;				
			}
			ul, ol{
				list-style: none;
			}
			a{
				color: @noir;
				text-transform: uppercase;
				font-family: @font_RR;
				font-size: 14px;
			}
			li{
				display: block;
				padding-bottom: 10px;
                -webkit-column-break-inside: avoid; /* Chrome, Safari */
               page-break-inside: avoid;           /* Theoretically FF 20+ */
               break-inside: avoid-column;         /* IE 11 */
			}
			.sub-menu{
				padding-top: 15px;
				padding-left: 10px;
				font-size: 12px;
				a{
					color: @noir;
					text-transform: capitalize;
					font-size: 12px;
				}
				.sub-menu{
					padding-top: 5px;
					a{
						font-family: @font_RR;
					}
					li{
						&:nth-last-child(1){
							padding-bottom: 0;
						}
					}
				}
			}
		}

		.menu-menu-header-container{
			display: none;
			width: 100%;
			font-size: 12px;

			img{
				display: none !important;
			}

			ul, ol{
				list-style: none;
			}

			a{
				color: @noir;
			}

			li{
				display: inline-block;
				text-align: left;
				vertical-align: top;

				.menu-item-has-children{
					display: block;
				}
			}
		}

	}

}

@media screen and (min-width: 1024px) {

	footer{

		.footer_assurances{

			.assurances_assurance{
				margin-left: 115px;

				&:nth-child(1){
					margin-left: 0;
				}

			}
		}

	}

}
 
 
