.expertise-detail{
	.owl-stage-outer{
		overflow: hidden;		
	}
	.visuel_expert{
		padding: 0 10px;
		margin-top: 8px;
		.visuel_expert_1,
		.visuel_expert_2{
			display: block;
			height: 360px;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
		}
	}
}

@media screen and (min-width: 640px) {

	.expertise-detail{
		.visuel_expert{
			padding: 50px 10px 0;
			max-width: 960px;
			box-sizing: border-box;
			margin-left: auto;
			margin-right: auto;
			.visuel_expert_1{
				float: left;
				width: 63.5%;
			}
			.visuel_expert_2{
				float: right;
				width: 34%;
			}
		}
	}

}

@media screen and (min-width: 960px) {

	.expertise-detail{
		.visuel_expert{
			padding: 50px 0 0;
			.visuel_expert_1{
				float: left;
				width: 67%;
			}
			.visuel_expert_2{
				float: right;
				width: 31%;
			}
		}

		.bottom_block{
			.bottom_block_offre{
				.cta{
					bottom: 30px;
				}
			}
		}
	}

}