.bandeau_pinterest{
	text-align: center;
	width: 246px;
	margin: 0 auto;

	.bandeau_pinterest_item{
		padding-bottom: 20px;
		span{
			font-family: Arial;
			font-weight: bold;
		}
		img{
			display: block;
			margin: 0 auto 15px;
			width: 226px;				
			.mixin-grayscale();
		}
		.pinterest-like{
			display: block;
			background-image: url(../img/pinterest-like.svg);
			background-repeat: no-repeat;
			background-position: 0 0;
			display: inline-block;
			width: 20px;
			height: 17px;
			background-size: 20px 17px;
			vertical-align: middle;
			margin-right: 8px;
		}
	}

	.owl-item{
		&.active{
			.bandeau_pinterest_item{
				img{		
					.mixin-grayscale(0%);
				}
			}
		}
	}
}

@media screen and (min-width: 768px) {

	.bandeau_pinterest{
		width: 100%;
		.bandeau_pinterest_item{
			display: inline-block;
			margin: 30px 10px;
			//img{	
			//	.mixin-grayscale(0%);
			//}
		}
		
	}

}

@media screen and (min-width: 1024px) {

	.bandeau_pinterest{

		max-width: 980px;
		//overflow: hidden;

		//.owl-stage-outer{
		//	width: 80%;
		//}
//
		//.owl-nav{
		//	.owl-prev,
		//	.owl-next{
		//		top: 42%;
		//	}
		//	.owl-prev{
		//		left: 0;
		//	}
		//	.owl-next{
		//		right: 0;
		//	}
		//}

		.bandeau_pinterest_item{
			img{
				width: 306px;		
				//.mixin-grayscale(0%);
			}
			.pinterest-like{
				width: 25px;
				height: 23px;
				background-size: 25px 23px;
				margin-right: 12px;
			}
			span{
				font-size: 20px;
			}
		}
		
	}

}

