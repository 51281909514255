.carousel_produit{
	text-align: center;
	max-width: 960px;
	margin: 0 auto 10px;
	overflow: hidden;
	display: none;
	.carousel_produit_item{
		display: inline-block;
		color: @noir;
		width: 225px;
		img{
			max-width: 100%;
			height: auto;
		}
		.carousel_produit_titre{
			text-transform: uppercase;
			display: block;
			padding-bottom: 15px;
			font-size: 16px;
			padding-top: 8px;
			& > span:last-child{
				font-family: @font_RH;
			}
		}
	}
}

.choix_gamme{ 
	.choix_gamme_item{
		display: none;		
	}
	select.cs-select{
		display: none; 
	}

	.cs-skin-border{
		span{
			border:none;
		}
	}
}

.choix_gamme_item_mobile{
	border-bottom: 1px solid @orange;
	border-top: 1px solid @orange;
	text-transform: uppercase;
	color: @noir !important;
	font-size: 13px;
	text-align: center;
	margin: 20px 45px;
	box-sizing: border-box;
	width: ~'calc(100% - 90px)' !important;
	.cs-placeholder{
		color: @noir !important;
		font-size: 13px;
		text-align: center;
		padding-left: 0;
		padding-right: 0;
		&:after{
			display: inline-block;	
			content: ' ';
			width: 7px;
			height: 12px;
			color: transparent;
			background-position: 0 0;
			background-repeat: no-repeat;
			background-image: url(../img/carousel-fleche.svg);
			background-size: 7px auto;
			.mixin-rotate(90deg);
			position: relative;
			top: 1px;
			left: 20px;
		}
	}
	&.cs-active{
		.cs-placeholder{
			&:after{
			.mixin-rotate(90deg);
			position: relative;
			top: 1px;
			left: 20px;
			}
		}
	}
	.cs-options{
		color: @noir !important;
		border-bottom: 1px solid @orange;
		font-size: 13px;
		text-align: center;
		padding-left: 0;
		padding-right: 0;
		.cs-selected{
			color: @noir !important;
			padding-left: 0;
			padding-right: 0;
			span{
				&:after{
					display: none;
				}
			}
		}
	}
}

@media screen and (min-width: 768px) {

	.carousel_produit{
		padding-top: 30px;
	}

	.choix_gamme{
		width: 100%;
		text-align: center;
		padding-top: 40px;
		padding-bottom: 20px;
		.choix_gamme_item{
			display: inline-block;
			cursor: pointer;
			height: 38px;
			line-height: 38px;
			padding: 0 28px;
			border: solid 2px @blanc;
			.mixin-border-radius();
			text-transform: uppercase;
			.mixin-transition(border-color 250ms linear);

			&:hover{
				border-color: @noir;
			}

			&.active{
				font-family: @font_RH;
				border-color: @noir;
			}
		}

		.choix_gamme_item_mobile{
			display: none !important;
		}
	}
}

@media screen and (min-width: 1024px) {
	.choix_gamme{

		.choix_gamme_item_mobile{
			display: none !important;
		}
	}
}
