.collection{

	.titre{
		strong{
			display: inline-block;
		}
	}

	h1{
		font-size: 26px;
		font-family: @font_RR;
		line-height: 30px;
		text-transform: uppercase;
		padding: 20px 0 0;
		strong{
			font-family: @font_PB;
			display: block;
		}
	}

	.liste-collection{
		list-style: none;
		display: block;
		margin: 0 10px;
		li{
			display: block;
			width: 100%;
			margin: 5px 0;

		/*	.youtube-wrapper{
				position: relative;
				padding-bottom: 52.35%;
				padding-top: 25px;
				height: 0;
				iframe{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}*/

			a{
				color: #333333;
				display: block;
			}
			img{
				width: 100%;
				height: auto;
				display: block;
				margin: 0 auto;
			}
			p{
				margin-bottom: 10px;
			}

			&.collection-item-texte{
				padding: 55px 35px;
				box-sizing: border-box;
			}
		}

	}

	.collection_video_container{
		position:relative;
		min-height:200px;
		padding-top:20px;
		height:0;
		overflow:hidden;
		width: 100%;
		text-align: center;

		& iframe,
		& object,
		& embed {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
		}
	}

}

@media screen and (min-width: 640px) {
	.collection{

		h1{
			font-size: 38px;
			line-height: 43px;
			padding: 30px 0 22px;
			strong{
				display: inline;
			}
		}

		.collection_video_container  {
			min-height: 357px;
		}

		.liste-collection{
			li{
				width: 50%;
				padding: 5px;
				box-sizing: border-box;
				&.double-width{
					width: 100%;
				}

				&.collection-item-texte{
					padding: 105px 35px;
				}
				/*img{
					width: auto;
				}*/
			}

		}

		.bg_mag_gris{
			background: @gris_clair;
			padding: 16px 0;
			margin: 30px 0;
		}
	}
}

@media screen and (min-width: 1024px) {
	.collection{

		h1{
			font-size: 45px;
			line-height: 50px;
			padding: 60px 0 45px;
		}
		.liste-collection{
			margin: 0;
			li{
				width: 33.3333%;
				margin: 0;
				&.double-width{
					width: 66.6666%;
				}
				&.visuel-3{
					margin-top: 38px;
				}
				&.visuel-join-the-club{
					height: 400px;
					a{
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						left: 0;
						right: 0;
					}
					img{
						max-width: 250px;
					}
				}
				&.collection-item-texte{
					height: 400px;

					/*a{
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						padding: 0 30px;
					}*/
				}
			}

		}


	}
}
