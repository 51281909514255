.slider_salons{

	.slider_salons_salon{
		display: inline-block;
		vertical-align: top;
		text-transform: uppercase;
		padding-bottom: 20px;
		max-width: 260px;
		a{
			color: @noir;
		}
		.slider_salons_salon_titre{
			display: block;
			padding-top: 12px;
			font-family: @font_PB;
		}
	}

}

.picto-salon{
	display: inline-block;
	background: url(../img/sprite-salon.png) no-repeat;
	overflow: hidden;
	text-indent: -9999px;
	text-align: left;
	background-size: 52px 63px;
	vertical-align: middle;
	&.salon-salon-new{
		background-position: -2px 0;
		width: 48px;
		height: 15px;
	}
	&.salon-salon-club{
		background-position: -2px -17px;
		width: 34px;
		height: 15px;
	}
	&.salon-salon-jeudi{
		background-position: -2px -34px;
		width: 27px;
		height: 27px;
	}
}

@media screen and (min-width: 640px) {

}
@media screen and (min-width: 768px) {

	.slider_salons{
		.slider_salons_salon{
			margin: 0 10px;
		}
	}

}
@media screen and (min-width: 1024px) {

	.slider_salons{
		.slider_salons_salon{
			margin: 0 25px;
		}
	}
	
}
 
