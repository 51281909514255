
/* common cta modifier - (à déplacer) */
.cta{
	&.lcase{
		text-transform: none;
		font-family: "Raleway-Regular";
		font-size: 14px;
	}
	&.plus{
		padding-right: 40px;
		&:before{
			content: "+";
			font-size: 36px;
			background: none;
			right: 6px;
			top: 1px;

		}
		&:hover{
			&:before{
				top: 5px;
			}
		}
	}
	&.bg-orange{
		background: @orange;
		color: #FFFFFF;
		&:after{
			border-color: #FFFFFF;
		}
	}

}


/* specific for page article-magazine */

.article-magazine{

    .cta {
            min-width: 130px;
    }
	.titre{
		strong{
			display: inline-block;
            font-family:@font_PB;
		}
	}
    .exclue_pages{
        display:none;
    }

    .date_article{
        font-size:16px;
        font-family:@font_PB;
        display:block;
        padding-bottom:20px;
    }

    .tags_article{
        font-size:16px;
        color:@orange;
        font-family:@font_RR;
        display:block;
        padding:13px 0;

        a{
            color: @orange;
        }
    }

	.article-magazine_article{
		padding: 16px 0;

		.retour{
			color: @gris;
			font-family: "Raleway-Light";
			font-size: 13px;
		}
		h1{
			font-size: 26px;
			text-transform: uppercase;
			padding: 0 10px 20px;
			line-height: 1.2;
			strong{
				font-family: 'Raleway-Black';
			}
		}

		.visuel-article-magazine{
			position: relative;
			overflow: hidden;
			img{
				max-width: 100%;
				display: block;
			}
            .date_article{
                position: absolute;
                top:0;
                right:0;
                color:@blanc;
                background: url('../img/date_article_mag_mob.png') left top no-repeat;
                width: 95px;
                height: 60px;
                line-height:54px;
                font-family:@font_RR;
                font-size: 14px;
            }
			.social{
				position: absolute;
				bottom: 0;
				right: 0;
				.social-fb,
				.social-tw,
				.social-pin{
					display: block;
					width: 42px;
					height: 42px;
					color: #FFFFFF;
					font-size: 10px;
					padding-top: 23px;
					box-sizing: border-box;
				}
				.social-fb{
					background: #3b5998 url(../img/social/picto-fb.png) no-repeat center 8px;
				}
				.social-tw{
					background: #1da1f2 url(../img/social/picto-tw.png) no-repeat center 8px;
				}
				.social-pin{
					background: #b00616 url(../img/social/picto-pin.png) no-repeat center 8px;
				}
			}
		}

		.article-magazine_infos{
			padding: 0 14px;

			p{
                text-align: justify;
				padding: 0 0 20px 0;
                strong{
                    font-family:@font_RH;
                }
			}
		/*	h2{
				font-size: 18px;
				text-transform: uppercase;
				padding: 16px 0 4px;
				font-family: 'Raleway-Black';
			}*/

		}
        .titre_article{
            font-size:18px;
            text-transform: uppercase;
            padding:35px 0;
            display:block;
			font-family:@font_RH;
        }

	}

    .article-magazine_contenu_centre{
		.titre{
			padding: 20px 40px;
		}
        .contenu_centre_column{
          width:100%;
          text-align: justify;
          padding:0 14px;
          box-sizing: border-box;
          .ss_titre_column{
              display:block;
              color:@orange;
              font-family:@font_RB;
              padding-bottom: 10px;
          }
          p{
              padding-bottom:25px;
			  strong{
				  font-family:@font_RB ;
			  }
          }
		  .cta-outer{
			margin-top: 10px;
		  }
        }
    }

    .article-magazine_video_container{
        width: 90%;
        margin: 35px auto 15px;
    }


    .content_multi_columns{
        padding:30px 0;
        background:#f6f6f6;
        .content_multi_columns_one{
            padding:0 24px;
            .icon-column{
                display:block;
                border:2px solid @orange;
                width: 73px;
                height: 73px;
                border-radius: 50%;
                margin: 0 auto 30px;
                line-height: 90px;
                text-align: center;
                img {
                    max-height:30px;
                }
            }
            .titre_article{
                font-size:16px;
                text-transform: uppercase;
                padding:0 0 18px;
                display:block;
                strong{
                    font-family:@font_RH;
                }
                p{
                    padding: 0;
                    text-align: center;
                }
            }
            p{
                text-align: justify;
				padding: 0 0 20px 0;
                strong{
                    font-family:@font_RH;
                }
			}
        }
    }

    .content_produits_associes{
        img{
            max-width: 225px;
            max-height: 225px;
            width: auto;
            height: auto;
        }
        .produits_associes{
            color:@noir;
            display: block;
            .produits_associes_nom_produit{
                font-size:16px;
                padding: 10px 0;
                strong{
                    font-family:@font_RH;
                }
            }
            .cta {
                margin-top: 25px;
            }
        }
    }

    .article-magazine_liste{
        padding:30px 20px;
        text-align: left;
        .article-magazine_liste__single{
            margin-bottom: 25px;
            .article-magazine_liste__number{
                width:46px;
                height:46px;
                background:@orange;
                color:@blanc;
                font-size:20px;
                border-radius:50%;
                text-align: center;
                line-height:46px;
                font-family:@font_RB;
                display:inline-block;
                vertical-align: middle;
            }
            .article-magazine_liste__texte{
                display: inline-block;
                width: 74%;
                margin-left: 20px;
                box-sizing: border-box;
                vertical-align: middle;
                font-size: 16px;
                line-height: 22px;
                strong{
                    font-family:@font_RH;
                }
            }
        }
        .titre_puce{
            font-family:@font_RB;
            font-size:16px;
            display:block;
            padding:30px 0  40px;
        }
        ul{
            list-style-type: none;
            li{
                font-size:16px;
                color:@noir;
                display:inline-block;
                box-sizing: border-box;
                /* width: 88%; */
                vertical-align: top;
                text-align: justify;
                margin-bottom:30px;
                position: relative;
                padding-left: 18px;
                &::before {
                    content: ' ';
                    background-color: #e67a2a;
                    width: 6px;
                    height: 6px;
                    display: block;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 5px;
                }
            }
        }
    }

    .article-magazine_citation{
        max-width:1200px;
        width:96%;
        padding: 35px 0 45px;
        margin: 0 auto;
        box-sizing: border-box;
        border-top:8px solid #f6f6f6;
        border-bottom:8px solid #f6f6f6;
        p{
            font-size:20px;
            line-height:24px;
            text-align: left;
            padding-left: 20%;
            position: relative;
            font-style:italic;
            strong{
                font-family:@font_RH;
            }
            &::before, &::after {
                content: '';
                width:30px;
                height:23px;
                display: inline-block;
            }
            &::before{
                background: url(../img/cote_top.png) no-repeat left top;
                position: absolute;
                left: 20px;
                top: -15px;
            }
            &::after{
                background: url(../img/cote_bottom.png) no-repeat left top;
                position: relative;
                top: 15px;
			    left: 20px;
            }
        }
    }

    .bloc_newsletter.full_width{
        margin:25px auto 35px;
    }

	.bg_mag_gris {
		.titre{
			padding: 30px 40px 10px;
		}
	}

	.content_produits_associes{
		.titre {
		    padding: 30px 20px 20px;
			strong{
				display: block;
			}
		}
	}

}

@media screen and (min-width: 640px) {
    .article-magazine{
        .tags_article{
            padding: 15px 0 0;
        }
        .titre{
            strong{
                display:block;
            }
        }
        .article-magazine_contenu_centre{
            padding: 0 5%;
            h2 {
                padding: 30px 20px 40px;
            }
            .contenu_centre_column{
                -moz-column-count: 2;
                -webkit-column-count: 2;
                column-count: 2;
                -webkit-column-gap: 50px; /* Chrome, Safari, Opera */
                  -moz-column-gap: 50px; /* Firefox */
                  column-gap: 50px;
                  box-sizing: border-box;
				  p{
					  vertical-align: top;
					  display: inline-block;
				  }
            }
            .cta-outer{
                margin-top: 30px;
            }
			.titre{
				padding: 20px 40px 40px;
			}
        }

        .article-magazine_video_container{
            margin: 45px auto;
        }

        .content_multi_columns {
            .content_multi_columns_one {
                padding: 0 24px 46px;
                width: 50%;
                box-sizing: border-box;
                display: inline-block;
                &:last-child{
                    width:100%;
                    padding-bottom:25px;
                }
                .icon-column{
                    width:134px;
                    height:134px;
                    line-height: 173px;
                    img{
                        max-height:60px;
                    }
                }
            }
			.slider_multi_column{
				   padding: 0 50px;
			}
        }



        .article-magazine_article{
            h1{
                font-size: 45px;
                padding: 16px 16px 20px;
            }
            .visuel-article-magazine{
				max-width: 96%;
			    margin: 0 auto;
				img{
					width: 100%;
				}
            }
            .article-magazine_infos{
                padding: 0 5%;
            }
            /*.titre_article {
                font-size: 30px;
            }*/
        }

        .article-magazine_liste{
            padding: 70px 50px 30px;
            .article-magazine_liste__single{
                .article-magazine_liste__texte{
                    width: 88%;
                }
            }
        }

        .article-magazine_citation{
            width: 86%;
            padding: 60px 0 45px;
            margin: 0 auto 40px;
            p{
                padding: 0 50px;
                &::before{
                    left: 10px;
                    top: -25px;
                }
                &::after{
                    left: 20px;
                }
            }
        }

        .content_produits_associes{
            .titre{
                padding-bottom: 40px;
                strong{
                    display:inline-block;
                }
            }
            .produits_associes{
                width: 33%;
                display: inline-block;
            }
        }

        /*.bg_mag_gris{
        background: @gris_clair;
        padding: 16px 0;
        margin: 30px 0;
        }*/
    }
}


@media screen and (min-width: 1024px) {
	.article-magazine{
        .article-magazine_contenu_centre{
            margin: 0 auto;
            max-width: 950px;
            .contenu_centre_column{
                .ss_titre_column{
                    padding-bottom:30px;
                }
            }
			.titre{
				 padding: 20px 40px 60px;
			}
        }

        .date_article {
            padding-bottom: 0;
            padding-top: 35px;
        }

        .content_multi_columns{
            padding: 80px 0;
            .content_multi_columns_one{
                width: 33%;
                padding-bottom: 0;
                &:last-child{
                    width: 33%;
                    padding-bottom: 0;
                }
            }
        }

		.article-magazine_article{
			padding-bottom: 20px;
            margin: 0 auto;
            max-width: 950px;
			.visuel-article-magazine{
				display: inline-block;
				width: 56%;
				vertical-align: top;
				max-width: 746px;
                .date_article{
                    display:none;
                }
			}

			.article-magazine_infos{
				display: inline-block;
				width: 38%;
				padding: 0 3%;
				vertical-align: top;
				text-align: left;

				h1{
					font-size: 45px;
					padding: 16px 0 0;
					margin-bottom: -10px;
                    line-height: 48px;
					strong{
						display: block;
					}
				}
			}

		}



        .article-magazine_liste{
            max-width: 980px;
            margin: 0 auto;
            padding: 70px 50px 30px;
            .article-magazine_liste__single{
                max-width: 780px;
                margin: 0 auto 25px;
                .article-magazine_liste__texte{
                    width: auto;
                    max-width: 700px;
                    letter-spacing: 0px;
                }
            }
        }

        .article-magazine_video_container{
            max-width: 950px;
            margin: 50px auto;
        }

        .slider_multi_column{
            margin: 0 auto;
            max-width: 950px;
        }

        .article-magazine_citation{
            padding: 60px 0;
            p {
                padding: 0 100px;
                &::before{
                    left: 50px;
                }
            }
        }

        .content_produits_associes {
            margin: 0 auto;
            max-width: 950px;
            padding-bottom: 30px;
            .titre{
                padding-top: 40px;
            }
         }

        .titre {
             padding-top: 50px;
         }
	}
}
