.homepage{
	text-align: left;
	p{
		text-align: center;
	}
	.homepage_tetiere{
		height: 73vw;
		width: 100%;
		position: relative;
		background-position: left top;
		background-repeat: no-repeat;
		background-size: auto 73vw;
		background-color: @blanc;
		box-sizing: border-box;
		background-image: url(../img/tetiere-home-fabio-salsa.png);

		padding-top: 73vw;

		.homepage_tetiere_link{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}

		h1{
			position: absolute;
			bottom: 1vw;
			left: 10px;
			font-size: 5.4vw;
			text-transform: uppercase;
			z-index: 2;
			span{
				display: inline-block;
				padding: 1.6vw;
				background-color: @blanc;
				margin: 0.6vw 0;
			}
			p{
				text-align: left;
			}
		}

		.copyright{
			font-size: 5px;
			position: absolute;
			left: -20px;
			top: 30px;
			.mixin-rotate(90deg);
			color: @noir;
			z-index: 2;
		}


		.homepage_tetiere_offre_locator{
			float: left;
			width: 100%;
		}

		.homepage_tetiere_offre,
		.homepage_tetiere_locator{
			background-repeat: no-repeat;
			background-position: center;
			width: 100%;
			border: solid 10px @blanc;
			height: 77.6vw;
			text-align: center;
			box-sizing: border-box;
			max-height: 260px;
			position: relative;
			z-index: 2;
		}

		.homepage_tetiere_offre{
			background-size: 300px auto;
			background-image: url(../img/tous-les-jeudis.png);
			background-color: @orange;
			border-top: none;
		    background-position: center 15px;

		    display: block;
			.cta{
				position: absolute;
				bottom: 20px;
				left: 50%;
				margin-left: -55px;
			}
		}

		.homepage_tetiere_locator{
			background-size: cover;
			background-image: url(../img/background-locator.png);
			font-size: 20px;
			line-height: 28px;
			text-transform: uppercase;
			color: @blanc;
			padding-top: 30px;
			span{
				display: block;
			}

			.homepage_tetiere_locator_form{
				background-color: @blanc;
				margin: 0 8px;
				width: ~"calc(100% - 16px)";

				input[type="text"]{
					line-height: 42px;
					height: 42px;
					background-color: @blanc;
					width: ~"calc(100% - 45px)";
					font-size: 12px;
					color: @noir;
					font-family: @font_RR;
					float: left;
					border: none;
					padding: 0 25px;
					box-sizing: border-box;
					letter-spacing: 1px;
					&::-webkit-input-placeholder {
					  color: @noir;
					}
					&::-moz-placeholder {
					  color: @noir;
					}
					&:-ms-input-placeholder {
					  color: @noir;
					}
					&:-moz-placeholder {
					  color: @noir;
					}
				}
				input[type="submit"],
				button{
					line-height: 42px;
					height: 42px;
					background-color: @blanc;
					width: 45px;
					float: left;
					font-size: 12px;
					color: @noir;
					font-family: @font_RB;
					border: none;
					position: relative;
					&:before{
						content: ' ';
						width: 0;
						height: 20px;
						display: inline-block;
						border-style: solid;
						border-width: 1px;
						border-color: @noir;
						.mixin-rotate();
						position: absolute;
						left: 3px;
						top: 9px;
					}
				}
			}
		}
	}

	.homepage_tendances{
		margin-top: 20px;
	}

	.homepage_tendances_produit{
		width: 306px;
		margin: 15px auto 0;
		text-align: center;
		display: block;
		color: @noir;
		img{
			display: block;
		}
		p{
			padding: 16px 0 8px;
		}
		.homepage_tendances_produit_label{
			font-size: 16px;
			font-family: @font_RR;
			text-transform: uppercase;
			padding-top: 18px;
			display: block;
			p{
				padding-top: 0;
			}
			strong{
				font-family: @font_RH;
			}
		}
	}

	.homepage_tendances_tendance{
		width: 306px;
		margin: 15px auto 0;
		text-align: center;
		display: block;
		color: @noir;

		p{
			text-align: justify;
			padding: 6px 15px;
			line-height: 1.2;
		}

		.homepage_tendances_tendance_visu{
			position: relative;
			img{
				display: block;
			}
			.figure{
				position: absolute;
				bottom: 0;
				right: 0;
				color: @blanc;
				text-align: center;
				text-transform: uppercase;
				padding: 35px 0 12px 20px;
				width: 200px;
				overflow: hidden;

				background-position: left top;
				background-size: 110% auto;
				background-repeat: no-repeat;
				strong{
					font-family: @font_RH;
					display: block;
				}

				p{
					position: relative;
					z-index: 2;
					padding: 0;
					text-align: center;
					strong{
						position: relative;
						z-index: 2;
					}
				}

				/*&:before,
				&:after{
					content: ' ';
					display: block;
					position: absolute;
					width:1px;
					height:100px;
					border:1px solid @jaune;
					border-width:120px;
					z-index: 1;
					border-color:transparent transparent transparent @jaune;
				}

				&:before{
					top: -100px;
					left: -120px;
					.mixin-rotate(215deg);
				}

				&:after{
					top: -40px;
					left: 50px;
					.mixin-rotate(125deg);
				}*/

				&.noir{
					/*&:before,
					&:after{
						border-color:transparent transparent transparent @noir;
					}*/
					background-image: url(../img/formes/noir.svg);
				}
				&.parme{
					/*&:before,
					&:after{
						border-color:transparent transparent transparent @parme;
					}*/
					background-image: url(../img/formes/parme.svg);
				}
				&.rose{
					/*&:before,
					&:after{
						border-color:transparent transparent transparent @rose;
					}*/
					background-image: url(../img/formes/rose.svg);
				}
				&.vert{
					/*&:before,
					&:after{
						border-color:transparent transparent transparent @vert;
					}*/
					background-image: url(../img/formes/vert.svg);
				}
				&.bleu{
					/*&:before,
					&:after{
						border-color:transparent transparent transparent @bleu;
					}*/
					background-image: url(../img/formes/bleu.svg);
				}
				&.orange{
					/*&:before,
					&:after{
						border-color:transparent transparent transparent @orange;
					}*/
					background-image: url(../img/formes/orange.svg);
				}
			}
		}

		.homepage_tendances_tendance_titre{
			font-size: 16px;
			font-family: @font_RR;
			text-transform: uppercase;
			padding-top: 18px;
			display: block;
			text-align: center;
			p{
				padding: 0;
				text-align: center;
			}
			strong{
				font-family: @font_RH;
			}
		}



	}
		.homepage_video_container{
			position:relative;
    	/*	padding-bottom: 46.25%;*/
			min-height:200px;
			padding-top:30px;
			height:0;
			overflow:hidden;
			width: 100%;
			text-align: center;
			margin-top: 30px;

			& iframe,
			& object,
			& embed {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
			}
		}

		.homepage_video_texte{
			width: 100%;
			text-align: center;

			.homepage_video_texte_titre{
				font-size: 16px;
				font-family: @font_RR;
				text-transform: uppercase;
				padding-top: 18px;
				display: block;
				strong{
					font-family: @font_RH;
				}
			}

			p{
				padding: 15px;
			}
		}

}

@media screen and (min-width: 640px) {

	.homepage{

		.homepage_tetiere{
			h1{
				bottom: 3vw;
				font-size: 6.8vw;
				span{
					display: inline-block;
					padding: 2.5vw;
					margin: 1vw 0;
				}
			}
		}

		.homepage_tendances_left{
			float: left;
			margin-right: 20px;
		}
		.homepage_tendances_right{
			overflow: hidden;
		}
		.homepage_tendances{
			width: 632px;
			margin: 40px auto 0;
		}
		.homepage_tendances_tendance{
			margin-top: 0;
			margin-bottom: 15px;

			p{
				padding: 15px;
				line-height: 1.4;
			}

		}

		.homepage_video_container {
			min-height: 357px;
		}

	}

}

@media screen and (min-width: 768px) {

	.homepage{

		.homepage_tetiere{

			.homepage_tetiere_offre_locator{
				direction: rtl;
			}

			.homepage_tetiere_offre,
			.homepage_tetiere_locator{
				direction: ltr;
				display: inline-block;
				vertical-align: bottom;
				width: 50%;
				border-width: 20px;
			    max-height: 310px;
			}

			.homepage_tetiere_offre{
				border-right-width: 0px;
			    background-position: center 10px;
				background-size: 366px auto;
			}
			.homepage_tetiere_locator{
				border-left-width: 0px;
				max-height: 330px;

				.homepage_tetiere_locator_form{

					input[type="text"]{
						line-height: 52px;
						height: 52px;
						background-color: @blanc;
						width: ~"calc(100% - 55px)";
						font-size: 14px;
						padding: 0 30px;
					}
					input[type="submit"],
					button{
						line-height: 52px;
						height: 52px;
						width: 55px;
						font-size: 16px;
						&:before{
							top: 14px;
						}
					}
				}

			}

		}
	}


}

@media screen and (min-width: 1024px) {

	.homepage{
		text-align: left;
		p{
			text-align: center;
		}
		.cp{
			margin-bottom: 20px;
		}
		.homepage_tetiere{
			max-height: 630px;
			height: 50vw;
			background-size: auto 100%;
			padding-left: 54%;
			padding-right: 1.8%;
			padding-top: 5vh;
			margin-bottom: 40px;

			max-width: 1920px;

/*			max-width: 1300px;
			margin: 0 auto 40px;
			box-sizing: border-box;
			padding-left: 39%;

			padding-left: 50%;*/

			h1{
				position: inherit;
				bottom: inherit;
				left: inherit;
				font-size: 3vw;
				margin-bottom: 2vh;

/*				font-size: 2.2vw;
				font-size: 45px;*/

				span{
					padding: 1.5vw 0.5vw;
					margin: 0.3vw 0;
				}
			}

			.copyright{
				font-size: 10px;
				position: absolute;
				left: 20px;
				top: 95%;
				//bottom: 12px;
				color: @blanc;
				.mixin-rotate(0);
			}

			.homepage_tetiere_offre,
			.homepage_tetiere_locator{
				border: none;
				max-height: 260px;
				margin-top: 0;

				.homepage_tetiere_locator_form{

					input[type="text"]{
						line-height: 42px;
						height: 42px;
					}
					input[type="submit"],
					button{
						line-height: 42px;
						height: 42px;
						cursor: pointer;
						&:before{
							top: 10px;
						}
					}
				}

			}

			.homepage_tetiere_offre{
			    background-size: 300px auto !important;
			}

		}


		.homepage_tendances{

			width: 960px;
			margin: 40px auto 0;

			.homepage_video_container{
				width: 630px;
				float: right;

				padding-bottom:0;
				padding-top:0;
				height: 360px;
				overflow:visible;
				text-align: center;
				margin-top: 16px;

				& iframe,
				& object,
				& embed {
					position:relative;
					top:0;
					left:0;
					width:100%;
					height:100%;
				}
			}

			.homepage_video_texte{

				width: 630px;
				float: right;

				p{
					width: 55%;
					margin: 0 auto;
				}

			}
		}
		.homepage_tendances_tendance{
			margin-top: 0;
			margin-bottom: 15px;
			float: left;

			&:nth-child(2n+1){
				margin-right: 20px;
			}
		}



	}

}

@media screen and (min-width: 1930px) {

	.homepage{
		text-align: left;
		p{
			text-align: center;
		}
		.homepage_tetiere{

		    height: 630px;
		    width: 1920px;
		    padding-left: 1030px;
		    padding-right: 30px;
		    padding-top: 0;
		    margin-left: auto;
		    margin-right: auto;
		    box-sizing: border-box;

		    h1{
		    	font-size: 70px;
		    	span{
		    		display: block;
		    		width: 840px;
		    	}
		    }

		    .homepage_tetiere_offre_locator{
		    	width: 840px;
		    }

			.homepage_tetiere_offre,
			.homepage_tetiere_locator{
				height: 260px;
				width: 420px;

			}

		}

	}



}
