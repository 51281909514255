/* common block locator - (à déplacer) */
.block_locator{
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	height: 68vw;
	text-align: center;
	box-sizing: border-box;
	max-height: 260px;
	position: relative;
	margin-top: 20px;
}

.block_locator{
	background-size: cover;
	background-image: url(../img/background-locator.png);
	font-size: 24px;
	line-height: 28px;
	text-transform: uppercase;
	color: @blanc;
	padding-top: 30px;
	span{
		display: block;
	}

	.block_locator_form{
		background-color: @blanc;
		position: absolute;
		bottom: 14px;
		left: 0;
		right: 0;
		width: ~"calc(100% - 28px)";
		max-width: 360px;
		margin: 0 auto;

		input[type="text"]{
			line-height: 42px;
			height: 42px;
			background-color: @blanc;
			width: ~"calc(100% - 45px)";
			font-size: 12px;
			color: @noir;
			font-family: @font_RR;
			float: left;
			border: none;
			padding: 0 25px;
			box-sizing: border-box;
			letter-spacing: 1px;
			&::-webkit-input-placeholder {
			  color: @noir;
			}
			&::-moz-placeholder { 
			  color: @noir;
			}
			&:-ms-input-placeholder {
			  color: @noir;
			}
			&:-moz-placeholder {
			  color: @noir;
			}
		}
		input[type="submit"],
		button{
			line-height: 42px;
			height: 42px;
			background-color: @blanc;
			width: 45px;
			float: left;
			font-size: 12px;
			color: @noir;
			font-family: @font_RB;
			border: none;
			position: relative;
			&:before{
				content: ' ';
				width: 0;
				height: 20px;
				display: inline-block;
				border-style: solid;
				border-width: 1px;
				border-color: @noir;
				.mixin-rotate();
				position: absolute;
				left: 3px;
				top: 9px;
			}
		}
	}
}


.jeudi-20{

	.tetiere_jeudi{
		background: @noir url(../img/tetiere-jeudis-20.jpg) no-repeat right;
		background-size: cover;
		color: #FFFFFF;
		padding: 40px 20px 20px;

		&__titre{
			font-family: "Raleway-Black";
			text-transform: uppercase;
			font-size: 32px;
			&:after{
				content: "";
				display: block;
				background: url(../img/20pourcent.png) no-repeat center;
				height: 34px;
				background-size: contain;
				margin-top: 12px;
			}
		}

		p{
			padding: 16px 0;
		}
		.cta{
			border: 0;
			margin: 10px 0;
		}
		a{
			color: #FFFFFF;
			font-size: 13px;
		}
	}

}


@media screen and (min-width: 640px) {
	.jeudi-20{

		.tetiere_jeudi{
			padding-left: 12%;
			padding-right: 12%;
			margin-bottom: 24px;

			&__titre{
				font-size: 44px;
				line-height: 1;
				&:after{
					display: inline-block;
					width: 120px;
					height: 45px;
					vertical-align: middle;
					margin-top: -16px;
					margin-left: 20px;
				}
			}

			p{
				padding: 24px 0;
			}
		}

		.block_locator{
			font-size: 30px;
			line-height: 1.2;

			img{
				display: block;
				margin: 10px auto;
			}
			span:nth-child(2),
			span:nth-child(3){
				display: inline-block;
			}

			.block_locator_form{
				bottom: 30px;
			}

		}

	}
}


@media screen and (min-width: 640px) and (max-width: 1023px) {
	.jeudi-20{
		
	}

}


@media screen and (min-width: 1024px) {
	.jeudi-20{

		.tetiere_jeudi{
			padding-left: 20%;
			padding-right: 20%;
			padding-top: 70px;
			padding-bottom: 25px;
			.cta{
				margin: 12px 0 24px;
			}
		}

		.block_locator{
			margin-top: 60px;
			background-image: url(../img/bg-salon-desktop.jpg);
		}
		
	}
}