.astuce{
	margin: 15px 10px;

	.astuce_visuel{
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		height: 360px;
	}

	.astuce_texte{
		padding-top: 20px;
		padding-right: 50px;
		padding-left: 20px;
		text-align: right;

		h2{
			font-size: 24px;
			line-height: 28px;
			text-transform: uppercase;
			padding-left: 10%;
			strong{
				font-family: @font_PB;
				display: block;
			}
		}

		.quote{
			font-size: 14px;
			line-height: 20px;
			position: relative;
			font-style: italic;
			padding-top: 20px;
			&:before,
			&:after{
				content: ' ';
				display: inline-block;
				width: 24px;
				height: 19px;
				background-image: url(../img/quote.svg);
				background-repeat: no-repeat;
				background-position: 0 0;
			}
			&:before{
				margin-right: 10px;
			}
			&:after{
				.mixin-rotate(180deg);
				right: -30px;
				bottom: -6px;
				position: absolute;
			}
		}
	}
}

@media screen and (min-width: 640px) {

	.astuce{
		margin: 50px auto;
		padding: 0 10px;
		max-width: 960px;

		.astuce_visuel{
			background-position: left center;
			display: inline-block;
			vertical-align: middle;
			width: 57%;
		}

		.astuce_texte{
			display: inline-block;
			padding-top: 0;
			padding-right: 20px;
			padding-left: 30px;
			width: 42%;
			box-sizing: border-box;
			vertical-align: middle;

			h2{
				font-size: 30px;
				line-height: 34px;
			}

			.quote{
				padding-right: 25px;
				&:after{
					right: -5px;
				}
			}
		}
	}

}

@media screen and (min-width: 1024px) {

	.astuce{
		padding: 0;

		.astuce_visuel{
			width: 66%;
		}

		.astuce_texte{
			width: 33%;
			h2{
				padding-left: 0;
			}
		}
	}

}