#header_menu{
	display: inline-block;
	vertical-align: middle;

	.closeMenu{
		display: none;
	}
}
header{
	.menu-menu-header-container{

		display: inline-block;
		vertical-align: middle;
		position: relative;
		margin: 0 20px;

		ul, ol{
			list-style: none;
		}

		#menu-menu-header{
			display: inline-block;

			& > li{
				display: inline-block;
				/*padding: 0 10px;*/
				padding: 0 5px;
				font-family: @font_RM;
				font-size: 16px;
				&.menu-item-has-children{
					&::after{
						content: '';
						width: 9px;
						height: 6px;
						display: inline-block;
						background-image: url(../img/shape-nav.svg);
						background-repeat: no-repeat;
						background-position: 0 0;
						background-size: 9px 6px;
						vertical-align: middle;
					}
				}

				&.current_page_item{
					a{
						color: @orange;
					}
					.sub-menu{
						a{
							color: @noir;
						}
					}
				}

				&.only_mobile{
					display: none;
				}
				&.heavy{
					font-family: @font_RH;
				}
				a{
					color: @noir;
				}
				&.current_page_ancestor,
				&.orange{
					& > a{
						color: @orange;
					}
				}

				& > .sub-menu{
					display: none;
					width: 740px;
					padding: 35px 20px 20px;
					box-sizing: border-box;
					position: absolute;
					left: 0;
					background-color: @blanc;
					-webkit-column-count: 3;
					-moz-column-count: 3;
					-ms-column-count: 3;
					column-count: 3;	
					text-transform: capitalize;
					.menu-image-title-hide{
						.menu-image-title{
							display: none;
						}
					}
					img{
						max-width: 100%;
						max-height: 220px;
						height: auto;
						float: right;
					}
					& > li{
						font-family: @font_RB;
						/*display: inline-block;
						width: 32%;*/
						display: block;
						padding-bottom: 15px;
						-webkit-column-break-inside: avoid; /* Chrome, Safari */
						page-break-inside: avoid;           /* Theoretically FF 20+ */
						break-inside: avoid-column;         /* IE 11 */
						vertical-align: top;


						&.float_right {
							//float: right;
						}

						& > .sub-menu{
							padding: 10px 20px;
							font-family: @font_RR;
							li{
								&::before{
									content: '';
									width: 9px;
									height: 6px;
									display: inline-block;
									background-image: url(../img/shape-nav.svg);
									background-repeat: no-repeat;
									background-position: 0 0;
									background-size: 9px 6px;
									vertical-align: middle;
									.mixin-rotate(-90deg);
									margin-right: 5px;
								}
							}
						}

						&.page-break-inside-initial{
							-webkit-column-break-inside: initial; /* Chrome, Safari */
							page-break-inside: initial;           /* Theoretically FF 20+ */
							break-inside: initial;         /* IE 11 */
						}
					}
				}
			}
		}
	}

}

@media screen and (-webkit-min-device-pixel-ratio:0) { 

	header{
		.menu-menu-header-container{


			#menu-menu-header{

				& > li{

					& > .sub-menu{
						& > li{

							&.float_right {
								float: right;
							}


						}
					}
				}
			}
		}

	}

}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

	header{
		.menu-menu-header-container{


			#menu-menu-header{

				& > li{

					& > .sub-menu{
						& > li{

							&.float_right {
								display: inline-block;

								img{
									width: 100%;
									height: auto;
								}
							}


						}
					}
				}
			}
		}

	}
}


@media screen and (max-width: 1200px) {

	header{
		.menu-menu-header-container{
			margin: 0 10px;
			#menu-menu-header{

				& > li{
					/*font-size: 14px;
					padding: 0 7px;*/
					font-size: 12px;
					padding: 0 4px;
				}
			}
		}

	}

}


@media screen and (max-width: 1024px) {

	#header_menu{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		width: 380px;

		display: none;

		.closeMenu{
			display: block;
			width: 14px;
			height: 14px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: 14px;
			background-image: url(../img/close-menu.svg);
			position: absolute;
			right: 18px;
			top: 18px;
			z-index: 200;
			cursor: pointer;
		}
	}	

	header{
		.menu-menu-header-container{
			margin: 0;
			display: block;
			padding-top: 28px;
			background-color: @noir;
			overflow-y: scroll;
			overflow-x: hidden;
			/*box-sizing: border-box;*/

			&::-webkit-scrollbar-track
			{
				background-color: @gris_clair_2;
			}

			&::-webkit-scrollbar
			{
				width: 10px;
				background-color: @gris_clair_2;
			}

			&::-webkit-scrollbar-thumb
			{
				background-color: @noir;
				border: 2px solid @gris_clair_2;
			}
			&::-moz-scrollbar-track
			{
				background-color: @gris_clair_2;
			}

			&::-moz-scrollbar
			{
				width: 10px;
				background-color: @gris_clair_2;
			}

			&::-moz-scrollbar-thumb
			{
				background-color: @noir;
				border: 2px solid @gris_clair_2;
			}


			#menu-menu-header{
				background-color: @noir;
				width: 100%;

				& > li{
					font-size: 16px;
					padding: 0;
					display: block;
					background-color: @noir;
					padding: 15px 0 15px 0;
					line-height: 20px;
					border-bottom: solid 1px @gris_clair_2;
					font-family: @font_RR;

					&.current_page_item{
						a{
							color: @blanc;
						}
						.sub-menu{
							a{
								color: @blanc;
							}
						}
					}


				&.menu-item-has-children{
					position: relative;
					&::after{
						content: '+';
						color: @orange;
						width: 9px;
						height: 6px;
						display: block;
						background-image: none;
						background-repeat: no-repeat;
						background-position: 0 0;
						background-size: 9px 6px;
						position: absolute;
						left: 250px;
						top: 15px;
						font-size: 18px;
					}

					&.open{
						&::after{
							content: '-';
						}
					}
				}


					a{
						color: @blanc;
						background-color: @noir;
						display: inline-block;
						margin-left: 85px;
					}

					& > .sub-menu{
						position: inherit;
						width: inherit;
						-webkit-column-count: initial;
						-moz-column-count: initial;
						-ms-column-count: initial;
						column-count: initial;	
					}
					&.heavy{
						font-family: @font_RB;
					}

					&.only_mobile{
						display: block;
						a{
							color: @blanc;
							opacity: 0.3;
						}
						&.orange{
							a{
								color: @orange;
								opacity: 1;
							}
						}
					}

				& > .sub-menu{
					width: auto;
					width: initial;
					padding: 0;
					position: relative;
					left: 0;
					background-color: @noir;
					padding-top: 15px;

					//display: block;



					.menu-image-title-hide{
						.menu-image-title{
							display: none;
						}
					}
					img{
						display: none !important;
					}
					& > li{
						font-family: @font_RB;
						display: block;
						width: 100%;
						vertical-align: top;

						padding: 15px 0 15px 0;
						line-height: 20px;
						border-top: solid 1px @gris_clair_2;

						&.float_right {
							display: none;
						}

						& > .sub-menu{		
							padding: 10px 20px;
							font-family: @font_RR;
							li{
								font-size: 14px;
								//background-color: @gris_clair_2;
								padding: 15px 0;
								&::before{
									content: '';
									width: 9px;
									height: 6px;
									display: inline-block;
									background-image: url(../img/shape-nav.svg);
									background-repeat: no-repeat;
									background-position: 0 0;
									background-size: 9px 6px;
									vertical-align: middle;
									.mixin-rotate(-90deg);
									margin-right: 5px;
									position: relative;
									left: 80px;
								}
							}
						}
					}
				}


				}
			}
		}

	}


}


@media screen and (max-width: 640px) {
	#header_menu{
		width: 320px;
	}

	header{
		.menu-menu-header-container{
			width: 320px;

			#menu-menu-header{

				& > li{

					&.menu-item-has-children{
						&::after{
							left: 190px;
						}
					}


					a{
						margin-left: 25px;
					}


				& > .sub-menu{
					& > li{
						& > .sub-menu{	
							li{
								&::before{
									left: 20px;
								}
							}
						}
					}
				}


				}
			}
		}
		
	}	


}
