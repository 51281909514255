.mentions-legales{
	text-align: left;
	padding: 25px;
	h1{
		padding-bottom: 25px;
		text-transform: uppercase;
		font-size: 26px;
		line-height: 30px;
		font-family: @font_RR;
		text-align: center;
		strong{
			font-family: @font_RH;
		}
	}
	.para{
		font-size: 14px;
		line-height: 20px;
		padding-bottom: 20px;
		a{
			color: @noir;
			text-decoration: none;
			&:hover{
				text-decoration: underline;
			}
		}
		.para_titre{
			font-family: @font_RH;
			text-transform: uppercase;
			display: block;
			padding-bottom: 20px;
			span{
				color: @orange;
			}
		}
	}
}

@media screen and (min-width: 640px) {

	.mentions-legales{
		padding: 30px 65px;
		h1{
			font-size: 38px;
			line-height: 45px;
		}
		.para{
			padding-bottom: 30px;
			&.inline_block{
				display: inline-block;
				vertical-align: top;
				max-width: 55%;
				padding-right: 20px;
				box-sizing: border-box;
			}
		}

	}

}

@media screen and (min-width: 1024px) {

	.mentions-legales{
		padding: 30px 0;
		max-width: 960px;
		margin-left: auto;
		margin-right: auto;
		h1{
			padding-bottom: 50px;
		}
		.para{
			padding-bottom: 40px;
			&.inline_block{
				padding-right: 30px;
			}
		}

	}

}