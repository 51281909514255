.bottom_block{

	.bottom_block_offre_locator{
		width: 100%;
	}

	.bottom_block_offre,
	.bottom_block_locator,
	.bottom_block_decouvrez{
		background-repeat: no-repeat;
		background-position: center;
		width: 100%;
		border: solid 10px @blanc;
		text-align: center;
		box-sizing: border-box;
		position: relative;
	}

	.bottom_block_offre{			
		background-size: 300px auto;
		background-image: url(../img/tous-les-jeudis.png);
		background-color: @orange;
		border-top: none;
		height: 77.6vw;
		max-height: 260px;
		display: block;
		.cta{
			position: absolute;
			bottom: 20px;
			left: 50%;
			margin-left: -55px;
		}
	}

	.bottom_block_decouvrez{		
		background-size: cover;
		background-image: url(../img/bg-decouvrez.jpg);
		font-size: 20px;
		line-height: 28px;
		text-transform: uppercase;
		color: @blanc;
		padding: 40px 0 60px;
		position: relative;
		span{
			display: block;
		}
		.cta{
			position: absolute;
			bottom: 20px;
			left: 50%;
			margin-left: -55px;
		}

	}

	.bottom_block_locator{			
		background-size: cover;
		background-image: url(../img/background-locator.png);
		font-size: 20px;
		line-height: 28px;
		text-transform: uppercase;
		color: @blanc;
		padding-top: 30px;
		padding-bottom: 8px;
		span{
			display: block;
		}

		.para{
			font-size: 14px;
			line-height: 20px;
			margin-top: 15px;
			text-transform: capitalize;
			padding: 0 15px;
		}

		.bottom_block_locator_form{
			background-color: @blanc;
			margin: 15px 8px 0;
			width: ~"calc(100% - 16px)";
			/*position: absolute;
			bottom: 8px;
			left: 0;*/

			input[type="text"]{
				line-height: 42px;
				height: 42px;
				background-color: @blanc;
				width: ~"calc(100% - 45px)";
				font-size: 12px;
				color: @noir;
				font-family: @font_RR;
				float: left;
				border: none;
				padding: 0 25px;
				box-sizing: border-box;
				letter-spacing: 1px;
				&::-webkit-input-placeholder {
				  color: @noir;
				}
				&::-moz-placeholder { 
				  color: @noir;
				}
				&:-ms-input-placeholder {
				  color: @noir;
				}
				&:-moz-placeholder {
				  color: @noir;
				}
			}
			input[type="submit"],
			button{
				line-height: 42px;
				height: 42px;
				background-color: @blanc;
				width: 45px;
				float: left;
				font-size: 12px;
				color: @noir;
				font-family: @font_RB;
				border: none;
				position: relative;
				&:before{
					content: ' ';
					width: 0;
					height: 20px;
					display: inline-block;
					border-style: solid;
					border-width: 1px;
					border-color: @noir;
					.mixin-rotate();
					position: absolute;
					left: 3px;
					top: 9px;
				}
			}
		}
	}
}

@media screen and (min-width: 768px) {

	.bottom_block{
		.bottom_block_offre,
		.bottom_block_locator,
		.bottom_block_decouvrez{
			width: 50%;
			display: table-cell;
			float: none;
			vertical-align: middle;
			height: inherit;
			height: auto;
			max-height: 300px;
			border-width: 15px;
		}

		.bottom_block_offre{
			border-top: solid 15px @blanc;
		}


	}


}

@media screen and (min-width: 1024px) {

	.bottom_block{
		max-width: 960px;

		.bottom_block_offre{
			border-right: 0;
			max-height: auto;
			max-height: inherit;

			.cta{
				bottom: 50px;
			}
		}

		.bottom_block_decouvrez{
			font-size: 25px;
			line-height: 35px;
			.cta{
				bottom: 50px;
			}

		}

		.bottom_block_locator{
			border-left: 0;
			max-height: auto;
			max-height: inherit;
			padding-bottom: 38px;
			.para{
				padding: 0 40px;
			}
			.bottom_block_locator_form{
				width: 285px;
				margin: 30px auto;
			}
		}


	}


}