.produit_avant{

	margin-top: 15px;
	padding: 25px 5px 30px;
	text-align: center;

	.produit_avant_visuel{
		display: inline-block;
		max-height: 240px;
		max-width: 240px;
		width: auto;
		height: auto;
	}

	.produit_avant_texte{
		h2{
			font-size: 22px;
			line-height: 26px;
			padding: 15px 20%;
			text-transform: uppercase;
			strong{
				display: block;
				font-family: @font_PB;
			}
		}
		.para{
			font-size: 14px;
			line-height: 20px;
		}
		.cta{
			margin-top: 25px;
		}
	}

}

@media screen and (min-width: 640px) {

	.produit_avant{
		max-width: 960px;
		margin-top: 15px;
		padding:0;
		height:100%;
		text-align: center;
		margin: 50px 10px 0;
		box-sizing: border-box;

		.produit_avant_visuel{
			vertical-align: middle;
			max-height: 335px;
			max-width: 180px;
			width: auto;
			height: 100%;
			position: relative;
			left: 20px;
		}

		.produit_avant_texte{
			display: inline-block;
			vertical-align: middle;
			width: ~"calc(100% - 180px)";
			text-align: right;
			box-sizing: border-box;
			padding: 85px 0 75px 0;
			h2{
				font-size: 30px;
				line-height: 36px;
				padding: 0 0 18px;
			}
			.cta{
				margin-top: 28px;
			}
			.para{
				width: 80%;
				float: right;
			}
		}

	}

}

@media screen and (min-width: 980px) {

	.produit_avant{
		margin: 50px auto 0;
		padding: 45px;

		.produit_avant_visuel{
			max-height: 335px;
			max-width: 280px;
		}
		.produit_avant_texte{
			padding-right: 70px;
			padding-left: 60px;
		}
	}

}


@media screen and (min-width: 1024px){
	.produit_avant{
		 padding: 20px 45px;
		.produit_avant_texte{
			padding-right: 0;
		}
	}
}
