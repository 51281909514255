.bandeau_le_club{
	background-position: center;
	background-repeat: no-repeat;
	background-size: auto 100%;
	background-image: url(../img/bg-le-club.jpg);
	color: @blanc;
	text-align: center;
	margin: 30px 0 10px;
	width: 100%;
	padding: 20px 0 40px;
	position: relative;

	.bg_orange{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		background-color: @orange;
		opacity: 0.9;
	}

	.titre{
		color: @blanc;
		position: relative;
	}

	p{
		padding: 0 20px 25px;
		position: relative;
	}
}

@media screen and (min-width: 1024px) {

	.bandeau_le_club{
		padding-top: 50px;
		background-size: cover;
		.titre{
			
			strong{
				display: block;
			}

		}

		p{
			width: 450px;
			margin: 0 auto;
		}

	}

}