.conseil_expert{
	padding: 0 10px;
	
	.conseil_expert_texte{
		text-align: center;
		padding: 15px;
		h2{
			line-height: 26px;
			font-size: 22px;
			text-transform: uppercase;
			padding-bottom: 15px;
			strong{
				font-family: @font_PB;
			}
		}
		.para{
			font-size: 14px;
			line-height: 20px;
			p{
				padding-bottom: 15px;
			}
		}
	}

	.conseil_expert_visuel{
		background-size: cover;
		background-position: center;
		height: 440px;
	}

}

@media screen and (min-width: 640px) {

	.conseil_expert{
		padding: 0 10px;
		margin-top: 20px;
		max-width: 960px;
		box-sizing: border-box;
		text-align: right;
		
		.conseil_expert_texte{
			text-align: left;
			padding: 25px;
			display: inline-block;
			vertical-align: middle;
			width: 54%;
			box-sizing: border-box;
			h2{
				line-height: 30px;
				font-size: 34px;
				padding-bottom: 25px;
			}
		}

		.conseil_expert_visuel{
			height: 510px;
			width: 45%;
			display: inline-block;
			vertical-align: middle;
		}

	}
}


@media screen and (min-width: 980px) {

	.conseil_expert{
		padding: 0;
		margin: 20px auto 0;
		text-align: right;
		
		.conseil_expert_texte{
			padding: 25px;
			width: 420px;
			text-align: left;
			.para{
				padding-right: 40px;
			}
		}

		.conseil_expert_visuel{
			height: 510px;
			width: 510px;
			display: inline-block;
			vertical-align: middle;
		}

	}


}	