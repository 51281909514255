.expertises-en-salon{
	.tetiere{
		margin-bottom: 15px;
	}
	.nav_ancre{
		display: none;
	}
	.expertise{
		h2{
			span{
				display: none;
			}
			strong{
				font-family: @font_RR;
				font-weight: 100;
			}
			text-transform: uppercase;
			font-size: 18px;
			border-top: solid 1px @orange;
			line-height: 50px;
			padding-left: 10px;
			text-align: left;
			margin: 0 15px;
			display: block;
			width: ~'calc(100% - 30px)';
			box-sizing: border-box;
			position: relative;

			&:after{
				content: '+';
				position: absolute;
				right: 10px;
				font-size: 34px;
				color: @noir;
				font-family: @font_RR;
			}

			&.active{
				border-bottom: solid 1px @orange;

				&:after{
					content: '-';
				}
			}
		}
		.sous_titre{
			margin-top: 15px;
		}
		.expertise_item{
			padding: 8px;
			vertical-align: top;
			color: @noir;
			.expertise_item_visuel{
				position: relative;
				img{
					width: 100%;
					height: auto;
					display: block;
				}
				.cta_more{
					display: block;
					width: 71px;
					height: 44px;
					background-image: url(../img/cta-plus-blanc.svg), url(../img/bg-forme-1.svg);
					background-repeat: no-repeat;
					background-position: center 15px, center center;
					background-size: 27px 18px, 71px 44px;
					position: absolute;
					bottom: 0;
					right: 0;
				}
			}
			.expertise_item_titre{
				font-family: @font_RH;
				text-transform: uppercase;
				display: block;
				font-size: 16px;
				padding: 16px 0 10px;
			}
			.expertise_item_texte{
				font-size: 14px;
				line-height: 20px;
			}
		}
	}

}
@media screen and (min-width: 640px) {
	.expertises-en-salon{

		.nav_ancre{
			display: block;
			border-top: solid 1px @orange;
			border-bottom: solid 1px @orange;
			margin: 40px 15px 30px 15px;
			text-align: center;
			ul{
				li{
					display: inline-block;
					line-height: 64px;
					margin: 0 0 0 6px;
					font-size: 13px;
					&:before{
						content: ' ';
						width: 7px;
						height: 7px;
						display: inline-block;
						background-image: url(../img/separateur-ancre.svg);
						vertical-align: middle;
						margin-right: 6px;
						position: relative;
						top: -1px;
						left: -2px;
					}
					&:nth-child(1){
						&:before{
							display: none;
						}
					}
					a{
						color: @noir;
						text-transform: uppercase;
					}
				}
			}
		}

		.expertise{
			.expertise_content{
				display: block !important;
				text-align: center;
			    max-width: 980px;
			    margin-left: auto;
			    margin-right: auto;
			}

			h2{
				span{
					display: inline;
				}
				strong{
					font-family: @font_PB;
				}
				font-size: 30px;
				border-top: none;
				line-height: 40px;
				padding-left: 0;
				text-align: center;
				margin: 55px 0 25px;
				width: 100%;

				&:after{
					display: none;
				}

				&.active{
					border-bottom: none;
				}
			}
			.sous_titre{
				margin-top: 0;
				padding: 0 30px 30px;
				max-width: 800px;
				margin-left: auto;
				margin-right: auto;
				box-sizing: border-box;
			}

			.expertise_item{
				padding: 0;
				display: inline-block;
				width: 306px;
				margin: 8px;
			}

		}

	}

}
@media screen and (min-width: 1024px) {
	.expertises-en-salon{

		.nav_ancre{
			max-width: 960px;
			margin-left: auto;
			margin-right: auto;
			margin: 60px auto 26px;
			ul{
				margin-left: 10px;
				li{
					margin: 0 0 0 12px;
					font-size: 14px;
					&:before{
						margin-right: 12px;
					}
				}
			}
		}
	}
}



