.le-club{

	.tetiere{
		background: @noir url(../img/tetiere-le-club.jpg) no-repeat center;
		background-size: cover;
		color: #FFFFFF;
		padding: 40px 20px;

		h1{
			font-size: 30px;
			text-transform: uppercase;
			line-height: 1.2;
			margin-bottom: 36px;
			span{
				display: block;
			}
		}
		p{
			max-width: 1024px;
			margin: 0 auto;
		}

	}

	.content_le_club{
		padding: 16px 10px;
		max-width: 1000px;
		margin: 0 auto;
	}

	&__avantages{
		ul{
			list-style: none;
			li{
				text-align: center;
				&:before{
					content: "";
					background: url(../img/check-orange.svg) no-repeat center;
					width: 38px;
					height: 38px;
					display: inline-block;
				}
				span{
					text-transform: uppercase;
					font-family: 'Raleway-Black';
					font-size: 16px;
					display: block;
					margin: 10px 0;
				}
				div{
					margin-bottom: 30px;
					padding: 0 20px;
				}
			}
		}
	}

	&__programme{
		background: @orange;
		color: #FFFFFF;
		padding: 24px 8px;
		position: relative;

		&__borders{
			span{
				position: absolute;
				display: block;
				background: #FFFFFF;
			}
			span:nth-child(1){
				width: 20px;
				height: 1px;
				top: 10px;
				left: 10px;
			}
			span:nth-child(2){
				width: 20px;
				height: 1px;
				top: 10px;
				right: 10px;
			}
			span:nth-child(3){
				height: 20px;
				width: 1px;
				top: 10px;
				right: 10px;
			}
			span:nth-child(4){
				height: 20px;
				width: 1px;
				bottom: 10px;
				right: 10px;
			}
			span:nth-child(5){
				width: 20px;
				height: 1px;
				bottom: 10px;
				right: 10px;
			}
			span:nth-child(6){
				width: 20px;
				height: 1px;
				bottom: 10px;
				left: 10px;
			}
			span:nth-child(7){
				height: 20px;
				width: 1px;
				bottom: 10px;
				left: 10px;
			}
			span:nth-child(8){
				height: 20px;
				width: 1px;
				top: 10px;
				left: 10px;
			}
		}

		&__titre{
			font-size: 22px;
			text-transform: uppercase;
			line-height: 1.2;
		}

		p{
			padding: 10px 0;
		}
		img{
			max-width: 80%;
		}

		&__remise{
			font-size: 18px;
			text-transform: uppercase;
			font-family: "Raleway-Bold";
			margin: 0 -30px;
			line-height: 1.5;
			padding: 16px 0;
		}
		.cta-outer{
			margin: 10px 0;
			.cta{
				background-color: #FFFFFF;
				-ms-border-radius: 50px;
				-webkit-border-radius: 50px;
				-moz-border-radius: 50px;
				border-radius: 50px;
				line-height: 38px;
				height: 38px;
				padding-left: 34px;
				padding-right: 64px;
				font-size: 14px;
				&::after {
					right: 44px;
					top: -1px;
					height: 38px;
				}
				&::before{
					right: 18px;
					top: 13px;
				}
			}
		}
		a{
			color: #FFFFFF;
		}
	}

	.block_locator{
		margin-top: 10px;
		height: 330px;
	    max-height: 340px;
		p{
			font-size: 14px;
			line-height: 20px;
			text-align: center;
			margin: 20px auto;
			padding:0 5px;
			box-sizing: border-box;
			position: relative;
			text-transform: none;
		}
		span{
			padding: 3px 0;
		}
	}

	.bloc_newsletter{
		margin-top: 10px;
		max-width: none;
	}

}

/* only mobile */
@media screen and (max-width: 640px) {
	.le-club{

	}
}


/* min tablet */
@media screen and (min-width: 640px) {
	.le-club{

		.tetiere{
			padding-top: 50px;
			h1{
				font-size: 44px;
				span{
					display: inline-block;
				}
			}
		}

	}
}

/* min tablet 2 */
@media screen and (min-width: 768px) {
	.le-club{
		.tetiere{
		    background-size: 150%;
			background-position: left 37% bottom -160px;
		}
		&__avantages{
			ul{
				li{
					display: inline-block;
					vertical-align: top;
					width: 27%;
					padding: 24px 3%;
				}
			}
		}


		.le-club__padding{
			margin: 0 8%;
		}

		.tetiere {
			padding: 70px 20px;
		}

		&__programme{
			padding: 36px 36px 30px;

			&__borders{
				span{
					position: absolute;
					display: block;
					background: #FFFFFF;
				}
				span:nth-child(1),
				span:nth-child(2),
				span:nth-child(5),
				span:nth-child(6){
					width: 36px;
					height: 2px;
				}
				span:nth-child(3),
				span:nth-child(4),
				span:nth-child(7),
				span:nth-child(8){
					width: 2px;
					height: 36px;
				}

			}

			&__titre{
				font-size: 30px;
				span{
					display: block;
				}
			}
			p{
				padding: 10px 0 25px;
			}

			&__remise{
				font-size: 35px;
			}
			img {
				    max-width: 180px;
			}
		}

		.block_locator,
		.bloc_newsletter{
			display: inline-block;
			vertical-align: top;
			width: 48%;
			margin-top: 30px;
		}

		.block_locator{
			margin-right: 2%;
			font-size: 20px;
			line-height: 24px;
		}
		.bloc_newsletter{
			margin-left: 2%;
			height: 330px;
			box-sizing: border-box;
			background-position: center 65px;
		    padding: 150px 35px 18px;
			p{
				max-width: 240px;
				margin: 0 auto;
				padding-bottom: 52px;
			}
			.bloc_newsletter_form{
				width: 100%;
			}
		}


	}
}

/* only tablet */
@media screen and (min-width: 640px) and (max-width: 1023px) {
	.le-club{

	}
}

/* min desktop */
@media screen and (min-width: 1024px) {
	.le-club{
		.bloc_newsletter{
			p{
				padding-bottom: 18px;
			}
			.bloc_newsletter_form{
				width: 300px;
			}
		}
		.le-club__padding{
			margin: 0;
		}
		.tetiere {
			background-size: 109%;
			background-position: center center;
			.texte_sous_titre {
				line-height: 24px;
			}
		}
		&__programe{
			&__remise{
				font-size:40px;
			}
			p{
				padding: 5px 0 25px;
			}
		}
		.block_locator{
			font-size: 25px;
			line-height: 29px;
			margin-right: 0;
			img{
				display:block;
		    	margin: 0 auto 6px;
			}
			span {
				display: inline-block;
			}
			.block_locator_form {
				 max-width: 290px;
				 bottom: 50px;
			}
			p{
				padding: 0 35px;
				br{
					display:none;
				}
			}
		}
		.bloc_newsletter {
			width: 486px;
			margin-left:3%;
		}
	}
}
