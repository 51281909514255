.le-mag{
	
	.content_le_mag{

		.bandeau_mag,
		.liste_mag{

			.liste_mag_pagination{
				padding: 25px 0 45px;
				.page-numbers{
					display: inline-block;
					margin: 0 10px;
					font-family: @font_RR;
					color: @noir;
					font-size: 16px;
					vertical-align: top;
					line-height: 18px;
					&.next,
					&.prev{
						width: 10px;
						height: 18px;
						background-image: url(../img/pagination.svg);
						background-repeat: no-repeat;
						background-size: 10px 18px;
						position: relative;
						top: 2px;
						margin: 0 30px;
					}
					&.prev{
						.mixin-rotate(180deg);
					}
				}
				span{
					&.page-numbers{
						font-family: @font_RH;
						border-bottom: solid 2px @noir;
					}
				}
			}

			.bandeau_mag_item{
				.category{
					font-family: @font_PB;
					font-size: 14px;
					padding-bottom: 10px;
					display: block;
				}
			}

			.bandeau_mag_visu{
				img{			
					.mixin-grayscale(0);
				}

				.figure{

					&.noir{
						/*&:before,
						&:after{
							border-color:transparent transparent transparent @noir;
						}*/
						background-image: url(../img/formes/noir.svg);
					}
					&.parme{
						/*&:before,
						&:after{
							border-color:transparent transparent transparent @parme;
						}*/
						background-image: url(../img/formes/parme.svg);
					}
					&.rose{
						/*&:before,
						&:after{
							border-color:transparent transparent transparent @rose;
						}*/
						background-image: url(../img/formes/rose.svg);
					}
					&.vert{
						/*&:before,
						&:after{
							border-color:transparent transparent transparent @vert;
						}*/
						background-image: url(../img/formes/vert.svg);
					}
					&.bleu{
						/*&:before,
						&:after{
							border-color:transparent transparent transparent @bleu;
						}*/
						background-image: url(../img/formes/bleu.svg);
					}
					&.orange{
						/*&:before,
						&:after{
							border-color:transparent transparent transparent @orange;
						}*/
						background-image: url(../img/formes/orange.svg);
					}

				}

			}

		}

		h2.titre{
			font-size: 30px;
			line-height: 35px;
		}

		.liste_thematique{
			padding: 0 0 20px 35px;
			li{
				text-transform: uppercase;
				font-size: 14px;
				font-family: @font_RM;
				display: inline-block;
				text-align: left;
				padding: 10px 30px 10px 0;
				a{
					color: @noir;
				}
			}
		}

		.article_top{

			.article_top_header{
				text-align: center;
				padding: 15px 0 25px;
				.category{
					font-family: @font_PB;
					font-size: 14px;
					line-height: 20px;
					display: block;
				}
				.titre{
					font-family: @font_RT;
					font-size: 30px;
					line-height: 40px;
					padding: 0;
					strong{
						font-family: @font_RB;
					}
				}
			}

			.article_top_visu{
				height: 328px;
				width: 100%;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				position: relative;

				.figure{	
					position: absolute;
					bottom: 0;
					right: 0;
					color: @blanc;
					text-align: center;
					text-transform: uppercase;
					padding: 35px 0 12px 20px;
					width: 200px;
					overflow: hidden;

					background-size: 110% auto;
					background-repeat: no-repeat;
					background-position: left top;
					strong{
						font-family: @font_RH;
						display: block;
					}

					p{
						position: relative;
						z-index: 2;
						strong{
							position: relative;
							z-index: 2;
						}
					}		

					/*&:before,
					&:after{
						content: ' ';
						display: block;
						position: absolute;
						width:1px;
						height:100px;
						border:1px solid @noir;
						border-width:120px;
						z-index: 1;
						border-color:transparent transparent transparent @noir;
					}

					&:before{
						top: -100px;
						left: -120px;
						.mixin-rotate(215deg);
					}

					&:after{
						top: -40px;
						left: 50px;
						.mixin-rotate(125deg);
					}*/

					&.noir{
						/*&:before,
						&:after{
							border-color:transparent transparent transparent @noir;
						}*/
						background-image: url(../img/formes/noir.svg);
					}
					&.parme{
						/*&:before,
						&:after{
							border-color:transparent transparent transparent @parme;
						}*/
						background-image: url(../img/formes/parme.svg);
					}
					&.rose{
						/*&:before,
						&:after{
							border-color:transparent transparent transparent @rose;
						}*/
						background-image: url(../img/formes/rose.svg);
					}
					&.vert{
						/*&:before,
						&:after{
							border-color:transparent transparent transparent @vert;
						}*/
						background-image: url(../img/formes/vert.svg);
					}
					&.bleu{
						/*&:before,
						&:after{
							border-color:transparent transparent transparent @bleu;
						}*/
						background-image: url(../img/formes/bleu.svg);
					}
					&.orange{
						/*&:before,
						&:after{
							border-color:transparent transparent transparent @orange;
						}*/
						background-image: url(../img/formes/orange.svg);
					}

				}
			}

			.article_top_texte{
				text-align: justify;
				font-size: 14px;
				line-height: 20px;
				padding: 20px 15px;
				.article_top_cta{
					text-align: center;
					padding-top: 20px;
				}
			}

		}

		.bg_gris{
			background: @gris_clair;
		    padding-top: 10px;
		    margin-top: 20px;
		    padding-bottom: 25px;
		    margin-bottom: 10px;
		}

		.thematique_avant{
			.thematique_avant_titre{
				text-align: center;
				padding: 15px 0 25px;
				.category{
					font-family: @font_PB;
					font-size: 14px;
					line-height: 20px;
					display: block;
				}
				.titre{
					font-family: @font_RT;
					font-size: 30px;
					line-height: 40px;
					padding: 0;
					strong{
						font-family: @font_RB;
					}
				}
			}
			.thematique_avant_texte{
				text-align: justify;
				font-size: 14px;
				line-height: 20px;
				padding: 20px 30px;
			}
			.thematique_avant_visuel{
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				margin: 0 6px 20px;
				height: 338px;
				width: ~'calc(100% - 12px)';
			}
		}

	}


}



.le-mag-liste .tetiere{
	padding: 60px 0 90px;
}


@media screen and (min-width: 768px) {
	
	.le-mag{
		
		.content_le_mag{

			h2.titre{
				padding-top: 60px;
			}

			.article_top{

				padding: 0 10px 0 10px;

				.article_top_header{
					padding-top: 30px;
				}

				.article_top_visu{
					float: left;
					width: 55%;
					margin-right: 20px;
					height: 420px;
				}

				.article_top_texte{
					padding: 0;
					padding-top: 25px;
					text-align: justify;
					padding-right: 10px;
					.article_top_cta{
						text-align: left;
					}
				}

			}

			.bandeau_mag,
			.liste_mag{
				width: 650px;
				.bandeau_mag_item{
					&:nth-child(1){
						float: left;
					}
					&:nth-child(2){
						float: right;
					}
					&:nth-child(3){
						float: left;
					}
					.category{
						font-family: @font_PB;
						font-size: 14px;
						display: inline-block;
						padding-bottom: 14px;
					}
				}
			}

			.liste_mag{
				.bandeau_mag_item{
					&:nth-child(1){
						float: none;
					}
					&:nth-child(2){
						float: none;
					}
					&:nth-child(3){
						float: none;
					}
				}
			}

			.thematique_avant{
				padding: 0 10px 0 10px;
				.thematique_avant_titre{
					padding-top: 20px;
				}
				.thematique_avant_texte{
					padding: 0;
					text-align: justify;
					padding-right: 40px;
					padding-left: 30px;
					display: inline-block;
					vertical-align: middle;
					width: ~'calc(40% - 70px)';
				}
				.thematique_avant_visuel{
					height: 336px;
					width: 58%;
					display: inline-block;
					vertical-align: middle;
					margin: 0;
					padding: 0;
				}
			}

		}

	}

}

@media screen and (min-width: 1024px) {

	.le-mag{

		.content_le_mag{

			h2.titre{
				padding-top: 60px;
			}

			.article_top{
				padding: 50px 10px;
				max-width: 960px;
				margin-left: auto;
				margin-right: auto;

				.article_top_header{
					float: right;
					text-align: left;
					width: 41%;
					.titre{
						text-align: left;
						strong{
							display: block;
							text-align: left;
						}
					}
				}
				.article_top_visu{
					height: 540px;
					margin-right: 0;
				}
				.article_top_texte{
					float: right;
					text-align: justify;
					width: 41%;
					padding: 0;
				}
			}
			.bandeau_mag{
				width: 100%;
				text-align: center;
				.bandeau_mag_item{
					&:nth-child(1){
						float: none;
					}
					&:nth-child(2){
						float: none;
					}
					&:nth-child(3){
						float: none;
					}
				}
				&.liste_mag{
					width: 980px;
					margin-left: auto;
					margin-right: auto;
					.liste_mag_pagination{
						padding-bottom: 0;
					}
				}
			}
			.thematique_avant{
				padding: 0 10px 0 10px;
				max-width: 960px;
				margin-left: auto;
				margin-right: auto;
				.thematique_avant_visuel{
					width: 59%;
				}
			}
			.liste_thematique{
				width: 100%;
				text-align: center;
			}
			.liste_mag{
				width: 980px;
				.liste_mag_pagination{
					padding-bottom: 0;
				}
			}
		}
	}
}