@font-face {
  font-family: 'Raleway-Regular';
  src: url('../fonts/Raleway-Regular.eot');
  src: local('☺'),
  url('../fonts/Raleway-Regular.woff') format('woff'),
  url('../fonts/Raleway-Regular.ttf') format('truetype'),
  url('../fonts/Raleway-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-Thin';
  src: url('../fonts/Raleway-Thin.eot');
  src: local('☺'), url('../fonts/Raleway-Thin.woff') format('woff'),
  url('../fonts/Raleway-Thin.ttf') format('truetype'),
  url('../fonts/Raleway-Thin.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-Bold';
  src: url('../fonts/Raleway-Bold.eot');
  src: local('☺'),
  url('../fonts/Raleway-Bold.woff') format('woff'),
  url('../fonts/Raleway-Bold.ttf') format('truetype'),
  url('../fonts/Raleway-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-Black';
  src: url('../fonts/Raleway-Black.eot');
  src: local('☺'),
  url('../fonts/Raleway-Black.woff') format('woff'),
  url('../fonts/Raleway-Black.ttf') format('truetype'),
  url('../fonts/Raleway-Black.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-Medium';
  src: url('../fonts/Raleway-Medium.eot');
  src: local('☺'), url('../fonts/Raleway-Medium.woff') format('woff'),
  url('../fonts/Raleway-Medium.ttf') format('truetype'),
  url('../fonts/Raleway-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PlayfairDisplay-Bold';
  src: url('../fonts/PlayfairDisplay-Bold.eot');
  src: local('☺'),
  url('../fonts/PlayfairDisplay-Bold.woff') format('woff'),
  url('../fonts/PlayfairDisplay-Bold.ttf') format('truetype'),
  url('../fonts/PlayfairDisplay-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-Light';
  src: url('../fonts/raleway-light-webfont.eot');
  src: local('☺'),
  url('../fonts/raleway-light-webfont.woff') format('woff'),
  url('../fonts/raleway-light-webfont.woff2') format('woff2'),
  url('../fonts/raleway-light-webfont.ttf') format('truetype'),
  url('../fonts/raleway-light-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-Light';
  src: url('../fonts/Raleway-Light.eot');
  src: local('☺'), url('../fonts/Raleway-Light.woff') format('woff'),
  url('../fonts/Raleway-Light.ttf') format('truetype'),
  url('../fonts/Raleway-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('../fonts/fontawesome-webfont.eot?v=4.7.0');
  src: url('../fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
  url('../fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
  url('../fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),
  url('../fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
  url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}