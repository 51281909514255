.marque{
	text-align: left;
	p{
		text-align: center;
        strong {
            font-family:@font_RB;
        }
	}
    .cta-outer{
        text-align: center;
    }
    .titre strong {
		display:inline;
		font-family:@font_PB;
	}

    .titre_expertise{
		padding-left: 60px;
	    padding-right: 60px;
    }

    .marque_produits_offre{
        .owl-dots .owl-dot.active span{
            background-color: @blanc;
        }
    }

	.marque_tetiere{
		/*height: 73vw;*/
		width: 100%;
		position: relative;
		background-position: left top;
		background-repeat: no-repeat;
		background-size: auto 73vw;
		background-color: @blanc;
		box-sizing: border-box;
        padding: 150px 0 0;

        img {
            width:100%;
        }
		p{
			padding: 30px;
		}

		h1{
			font-size: 26px;
			text-transform: uppercase;
            position: absolute;
            top: 30px;
            left: 0;
            right: 0;
            text-align: center;
			span{
				display: inline-block;
				padding: 1vw;
				background-color: @blanc;
				margin: 0.6vw 0;
			}
			p{
				padding:0;
			}
		}
	}


    .marque_histoire {
        position: relative;
        padding-top: 80px;
        img {
            width:100%;
        }
        .marque_histoire_visuel {
            width: 94%;
            margin: 0 auto;
        }
        .marque_histoire_texte {
            padding: 30px 20px;
        }
        .titre{
            position: absolute;
            top:12px;
            left:0;
            right:0;
        }
    }


    .marque_bloc_expertise, .marque_bloc_mag {
        position: relative;
            text-align: center;
        img {
            width:100%;
        }
        .marque_bloc_expertise_visuel, .marque_bloc_mag_visuel {
            width: 94%;
            margin: 0 auto;
        }
        .marque_bloc_expertise_texte, .marque_bloc_mag_texte {
            padding: 30px 20px;
            p{
                padding-bottom: 25px;
            }
        }
        .titre{
            padding:0 0 25px 0;
            br {
                display:none;
            }
			p{
				padding-bottom:0;
			}
        }
    }

    .marque_bloc_mag{
        clear: both;
    }


    .marque_tetiere_offre{
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        border: solid 10px @blanc;
        height: 77.6vw;
        text-align: center;
        box-sizing: border-box;
        max-height: 260px;
        position: relative;
        float:left;
        margin: 20px auto 15px;
    }

    .marque_tetiere_offre{
        background-size: 300px auto;
        background-image: url(../img/tous-les-jeudis.png);
        background-color: @orange;
        border-top: none;
        .cta{
            position: absolute;
            bottom: 20px;
            left: 50%;
            margin-left: -55px;
        }
    }

    .service_expert{
        display:none;
    }

    .marque_produits{
        background: #08d2a5;
        text-align: center;
        padding:30px 2px;
        box-sizing: border-box;
        height: 380px;
        width: 94%;
        margin: 0 auto;
        overflow: hidden;
        h3{
            color:@blanc;
            text-transform:uppercase;
            font-family:@font_RR;
            font-size: 20px;
            line-height:28px;
            padding-bottom: 30px;
            strong{
                font-family:@font_RB;
                display:block;
            }
        }
        .owl-dots{
            position: relative;top: 50px;
        }
    }

    .bloc_newsletter.full_width{
        margin:0 auto 20px;
    }

    .bloc_newsletter{
        clear:both;
    }

}

@media screen and (min-width: 640px) {

	.marque{

		.marque_tetiere{
			h1{
				top: 26px;
				font-size: 38px;
				span{
					display: inline-block;
					margin: 1vw 0;
                    &:first-child{
                        display:block;
                    }
				}
                br{
                    display:none;
                }
			}
            img {
                width: 96%;
                margin: 0 auto;
				display: inline-block;
				vertical-align: middle;
            }
            p {
                padding: 30px 70px;
                text-align: left;
            }
			.marque_bloc_texte{
				display: inline-block;
				vertical-align: middle;
			}
		}
        .marque_histoire{
            direction: rtl;
            padding: 60px 14px 0;
            .titre {
               position: inherit;
               text-align: left;
               top:0;
               left:0;
               padding:0;
               margin-bottom: 30px;
               width: 70%;
           }
           p{
              text-align: left;
           }
           .marque_histoire_visuel {
               direction: ltr;
               width: 350px;
               height:515px;
               overflow:hidden;
			   display: inline-block;
			   vertical-align: middle;
               position: relative;
               img {
                   height:100%;
                   width:auto;
                   position: absolute;
                   right:-90px;
                   top:0;
               }
           }
           .marque_histoire_texte {
               padding:30px 30px 30px 70px;
               direction: ltr;
               width: 38%;
			   display: inline-block;
			   vertical-align: middle;
           }
        }
        .titre_expertise {
            padding:50px 20px;
            &.titre_expertise_bottom_20{
                padding-bottom: 20px;
            }
        }

        .marque_bloc_expertise{
            padding: /*60px*/ 14px 0;
            &:nth-child(even){
                direction: rtl;
            }
           .marque_bloc_expertise_visuel {
               width: 350px;
               height:515px;
               overflow:hidden;
			   display: inline-block;
		       vertical-align: middle;
               position: relative;
               img {
                   height:100%;
                   width:auto;
                   position: absolute;
                   left:-30%;
                   top:0;
               }
               &:nth-child(even){
                   direction: ltr;
               }
           }
           .marque_bloc_expertise_texte {
               padding:30px 30px 30px 50px;
               width: 38%;
			   display: inline-block;
		       vertical-align: middle;
               .titre {
                  position: inherit;
                  text-align: left;
                  top:0;
                  left:0;
                  padding:0;
                  margin-bottom: 30px;
              }
              p{
                 text-align: left;
              }
              &:nth-child(even){
                  direction: ltr;
              }
           }
        }
        /*.marque_bloc_expertise:first-of-type {
            padding-top:0 !important;
        }*/

        .marque_produits_offre{
            width: 745px;
            height: 360px;
            margin: 20px auto 20px;
            .marque_produits{
                max-width: 422px;
                float: left;
                height: 360px;
                h3 {
                    font-size:28px;
                    line-height: 38px;
                }

                .slider_pdt_Marque{
                    padding: 20px 0 35px;
                    img{
                        max-height: 126px;
                        vertical-align: bottom;
                    }
                }
            }

            .marque_tetiere_offre {
                background-position: -30px 20px;
                background-size: 340px auto;
                max-width: 300px;
                margin: 0;
                max-height: 360px;
                background-color:@orange;
                display: block;
                float: left;
                border: 0;
                margin-left: 20px;
            }
        }

        .marque_bloc_mag {
            padding: 0 14px;
            max-height: 475px;
            box-sizing: border-box;
            .marque_bloc_mag_visuel {
                width: 100%;
                max-width: 475px;
				display: inline-block;
				vertical-align: middle;
                height: 425px;
                overflow: hidden;
                img{
                    height:100%;
                    width:auto;
                }
            }
            .marque_bloc_mag_texte {
                padding: 0 0 0 60px;
                max-width: 200px;
				display: inline-block;
				vertical-align: middle;
                text-align: left;
                h2,p  {
                    text-align:left;
                }
            }
        }

        .bloc_newsletter.full_width {
            margin: 50px auto;
        }
    }

}

@media screen and (min-width: 1000px) {
    .marque{
		.titre {
			max-width: 70%;
			margin: 0 auto;
			padding: 50px 0;
		}
        .marque_tetiere{
            padding:0;
            h1 {
                line-height:52px;
                font-size: 45px;
                position: relative;
                top:0;
                left:0;
				font-size: 30px;
				line-height: 34px;
                text-align: left;
                padding: 0 30px;
                span {
                    margin: 0;
                    padding: 0.2vw 0;
                }
            }
            img {
                width: 60%;
                display: inline-block;
            }
            .marque_bloc_texte{
                width: 40%;
            }
            p {
                padding: 30px 30px;
            }
			.cta-outer {
	            padding: 0 0 0 30px;
	        }
        }
        .cta-outer {
            text-align: left;
        }
		.titre_expertise {
			max-width: 60%;
			margin: 0 auto;
		}
		.marque_histoire, .marque_bloc_expertise,  {
			 max-width: 890px;
			 box-sizing: border-box;
			 margin: 0 auto;
			 .titre{
				 margin: 0 0 30px 0;
			 }
		}

    }
}


@media screen and (min-width: 1025px){
	.marque{
		.titre {
			max-width: 40%;
            padding: 60px 0;
		}
		.marque_tetiere{
            img {
                width: 50%;
            }
			h1{
				 font-size: 45px;
				 line-height:50px;
				 padding: 0 60px;
				 span {
				    margin: 0;
				    padding: 0 0 0.8vw 0;
                        display: block;
				}
			}
			p{
				padding: 24px 60px 30px;
                max-width:400px;
			}
			.cta-outer {
			    padding: 0 0 0 60px;
			}
		}
	   .marque_histoire, .marque_bloc_expertise{
		    max-width: 950px;
            text-align: right;
			.marque_histoire_visuel {
				  width: 540px;
				 /* float: right;*/
				  img {
					  right: 0;
				  }
			}
			.marque_bloc_expertise_visuel{
				width:480px;
				height: 455px;
				img {
					width: 100%;
    				height: auto;
					left: 0;
				}
			}
			.marque_histoire_texte {
				width: 33%;
				padding: 30px 30px 30px 0;
			}
		   .marque_bloc_expertise_texte {
				padding: 30px;
				.titre {
					max-width: 260px;
                    br {
                        display:block;
                    }
				}
			}
	   }

	  /* .marque_bloc_expertise{
		   &.marque_bloc_expertise_visuel(even){
			  float: right;
		   }
	   }*/

	   .bandeau_expertises {
		    padding-top: 0;
		}
		.marque_produits_offre{
			 width: 960px;
			.marque_produits {
				 max-width: 525px;
				 .slider_pdt_Marque{
					 padding: 0 0 20px;
					 img{
						  max-height: 157px;
					 }
				 }
			}
			.marque_tetiere_offre  {
				max-width: 408px;
				background-position: 10px 20px;
    			background-size: 380px auto;
			}
		}
		.marque_bloc_mag {
			max-height: 550px;
            max-width: 960px;
            margin: 0 auto;
            padding: 0;
            text-align: left;
			.marque_bloc_mag_visuel {
				height: 550px;
				max-width: 630px;
			}
			.titre {
				max-width: 100%;
			}
		}
	}
}
