.tetiere{
	padding: 40px 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	color: @blanc;
	position: relative;

	.bg_noir{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #282828;
		opacity: 0.75;
		z-index: 0;
	}

	h1{
		font-size: 30px;
		line-height: 40px;
		text-transform: uppercase;
		font-family: @font_RH;
		position: relative;
	}

	.texte_sous_titre{
		font-size: 14px;
		line-height: 20px;
		max-width: 850px;
		text-align: center;
		margin: 30px auto 0;
		padding: 0 25px;
		box-sizing: border-box;
		position: relative;
	}

	a{
		position: relative;
	}

	.cta_trouver_salon{
		margin-top: 15px;
	}

			.tetiere_form{
				background-color: @blanc;
				margin: 0 15px;
				width: ~"calc(100% - 30px)";
				/*position: absolute;
				bottom: 8px;
				left: 0;*/
				position: relative;
				top: 20px;

				&:after{
					display: block;
					content: '';
					width: 100%;
					height: 0;
					clear: both;
				}

				input[type="text"]{
					line-height: 42px;
					height: 42px;
					background-color: @blanc;
					width: ~"calc(100% - 45px)";
					font-size: 12px;
					color: @noir;
					font-family: @font_RR;
					float: left;
					border: none;
					padding: 0 25px;
					box-sizing: border-box;
					letter-spacing: 1px;
					&::-webkit-input-placeholder {
					  color: @noir;
					}
					&::-moz-placeholder {
					  color: @noir;
					}
					&:-ms-input-placeholder {
					  color: @noir;
					}
					&:-moz-placeholder {
					  color: @noir;
					}
				}
				input[type="submit"],
				button{
					line-height: 42px;
					height: 42px;
					background-color: @blanc;
					width: 45px;
					float: left;
					font-size: 12px;
					color: @noir;
					font-family: @font_RB;
					border: none;
					position: relative;
					&:before{
						content: ' ';
						width: 0;
						height: 20px;
						display: inline-block;
						border-style: solid;
						border-width: 1px;
						border-color: @noir;
						.mixin-rotate();
						position: absolute;
						left: 3px;
						top: 9px;
					}
				}
			}


}
@media screen and (min-width: 640px) {
	.tetiere{
		padding: 65px 0;
		h1{
			font-size: 44px;
			line-height: 50px;
		}

		.tetiere_form{
			width: 590px;
			position: relative;
			top: 20px;
			margin-left: auto;
			margin-right: auto;
		}

	}
}
