.video-js{
    .vjs-big-play-button{
        top: 42%;
        left: 0;
        right: 0;
        margin: 0 auto;
        border:none;
        -webkit-border-radius: 0.4em;
        -moz-border-radius: 0.4em;
        border-radius: 0.4em;
        height: 38px;
        width: 55px;
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.7);
        line-height: 38px;
    }
    &:hover{
        .vjs-big-play-button{
            background-color: #000;
            background-color: rgba(40, 43, 47, 0.5);
        }
    }
    .vjs-poster{
        background-size: 100%;
    }
    &.vjs-fluid, &.vjs-16-9, &.vjs-4-3{
        height: 98%;
    }
}



@media screen and (min-width: 640px){
    .video-js{
        .vjs-big-play-button{
            height: 68px;
            width: 98px;
            line-height: 68px;
            -webkit-border-radius: 0.8em;
            -moz-border-radius: 0.8em;
            border-radius: 0.8em;
        }
        .vjs-poster{
            background-size: 100%;
        }
        &.vjs-fluid, &.vjs-16-9, &.vjs-4-3{
            height: 98%;
        }
    }
}
