.breadcrumb{
	display: none;
}
@media screen and (min-width: 1024px) {
	.breadcrumb{
		display: block;
		font-size: 10px;
		width: 100%;
		max-width: 1200px;
		text-align: left;
		color: @gris;
		padding: 15px 0;
		line-height: 15px;
		margin: 0 auto;
		li{
			display: inline-block;
			a{
				color: @gris;
			}
		}
	}
}