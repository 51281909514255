.detail-produit{
	
	.detail_produit_fiche{

		.detail_produit_fiche_titre{
			text-align: center;
			padding: 15px 0;
			a{
				font-family: @font_RL;
				font-size: 12px;
				color: @gris;
			}
			h1{
				font-size: 26px;
				text-transform: uppercase;
				line-height: 30px;
				padding-top: 15px;
				strong{
					font-family: @font_RH;
					display: block;
				}
			}
		}

		.detail_produit_fiche_visu{
			position: relative;
			img{
				max-width: 100%;
				height: auto;
				max-height: 330px;
			}
			.social{
				position: absolute;
				bottom: 0;
				right: 0;
				.social-fb,
				.social-tw,
				.social-pin{
					display: block;
					width: 42px;
					height: 42px;
					color: #FFFFFF;
					font-size: 10px;
					padding-top: 23px;
					box-sizing: border-box;
				}
				.social-fb{
					background: #3b5998 url(../img/social/picto-fb.png) no-repeat center 8px;
				}
				.social-tw{
					background: #1da1f2 url(../img/social/picto-tw.png) no-repeat center 8px;
				}
				.social-pin{
					background: #b00616 url(../img/social/picto-pin.png) no-repeat center 8px;
				}
			}
		}

		.detail_produit_fiche_texte{
			text-align: justify;
			padding: 15px 25px;
			font-size: 14px;
			line-height: 20px;
			.conseil{
				display: block;
				font-family: @font_RH;
				font-size: 16px;
				padding: 15px 0;
				text-transform: uppercase;
			}
			.liste_info{
				color: @orange;
				list-style: none;
				font-family: @font_RH;
				font-size: 16px;
				padding: 20px 0;
			}
			.dispo{
				font-family: @font_RL;
				font-size: 12px;
				color: @gris;
				font-style: italic;
				display: block;padding-bottom: 10px;
				text-align: center;
			}
			.block_cta{
				text-align: center;
			}
		}

	}

	.block_conseil_coupe{
		padding-bottom: 20px;
		.block_conseil_coupe_item{
			padding: 0 10px;
			text-align: center;
			.block_conseil_coupe_visuel{
				width: 100%;
				height: 338px;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				margin: 30px 0 20px;
			}
			.block_conseil_coupe_texte{
				h2{
					padding-bottom: 15px;
					text-transform: uppercase;
					font-size: 22px;
					line-height: 28px;
					strong{
						display: block;
						font-family: @font_PB;
					}
				}
				strong{
					font-family: "Raleway-Black";
				}
			}
		}
	}

	.carousel_produit{
		display: block;
		padding-bottom: 20px;
	}

}


@media screen and (min-width: 768px) {


	.detail-produit{
		
		.detail_produit_fiche{
			padding-left: 10px;
			padding-right: 10px;
			.detail_produit_fiche_titre{
				h1{
					font-size: 38px;
					line-height: 45px;
					strong{
						display: inline;
					}					
				}
			}

			.detail_produit_fiche_visu{
				img{
					max-height: 450px;
				}
			}

			.detail_produit_fiche_texte{
				padding: 30px 55px;
				.dispo{
					font-size: 14px;
				}
				.liste_info{
					padding: 40px 0;
				}
			}

		}

		.block_conseil_coupe{
			padding-bottom: 40px;
			max-width: 960px;
			margin-left: auto;
			margin-right: auto;
			.block_conseil_coupe_item{
				text-align: left;
				&:after{
					content: '';
					display: block;
					width: 100%;
					clear: both;
					height: 20px;
				}
				.block_conseil_coupe_visuel{
					width: 47%;
					height: 515px;
					margin: 0;
					display: inline-block;
					vertical-align: middle;
				}
				.block_conseil_coupe_texte{
					display: inline-block;
					vertical-align: middle;
					width: 52%;
					box-sizing: border-box;
					padding-left: 40px;
					padding-right: 30px;
					h2{
						padding-bottom: 25px;
						font-size: 30px;
						line-height: 40px;
					}
				}
				&:nth-child(1){
					direction: rtl;

					.block_conseil_coupe_texte{
						direction: ltr;
					}
				}
			}
		}

	}


}


@media screen and (min-width: 1024px) {


	.detail-produit{
	
		.detail_produit_fiche{
			max-width: 1200px;
			margin-left: auto;
			margin-right: auto;
			&:after{
				content: '';
				display: block;
				width: 100%;
				clear: both;
				height: 0;
			}

			.detail_produit_fiche_titre{
				text-align: left;
				padding: 0 0;
				float: right;
				width: 45%;
				box-sizing: border-box;
				padding-left: 60px;
				h1{
					font-size: 45px;
					line-height: 50px;
					padding-top: 20px;
					width: 60%;
				}
			}

			.detail_produit_fiche_visu{
				float: left;
				width: 55%;
				box-sizing: border-box;
				img{
					max-height: 750px;
				}
			}

			.detail_produit_fiche_texte{
				float: right;
				width: 45%;
				box-sizing: border-box;
				padding: 35px 10px 35px 60px;
				.dispo{
					text-align: left;
				}
				.block_cta{
					text-align: left;
				}
			}

		}

		.block_conseil_coupe{
			padding-top: 120px;
			padding-bottom: 40px;
			.block_conseil_coupe_item{
				&:after{
					height: 50px;
				}
				.block_conseil_coupe_visuel{
					width: 56%;
					height: 515px;
				}
				.block_conseil_coupe_texte{
					width: 42%;
					padding-left: 30px;
					padding-right: 50px;
				}
				&:nth-child(2){
					.block_conseil_coupe_texte{
						padding-right: 30px;
						padding-left: 50px;
					}
					&:after{
						height: 10px;
					}
				}
			}
		}

	}

}