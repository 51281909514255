.slider_tendances{
	margin: 25px auto;
	.slider_tendances_item{
		display: inline-block;
		vertical-align: middle;
		margin-bottom: 10px;
		width: 240px;
		img{
			max-width: 100%;
			height: auto;
		}
	}

}

@media screen and (min-width: 768px) {

	.slider_tendances{
		.slider_tendances_item{
			margin-right: 4px;
			margin-left: 4px;
			width: 236px;
		}

	}

}

@media screen and (min-width: 1024px) {

	.slider_tendances{
		.slider_tendances_item{
			margin-right: 10px;
			margin-left: 10px;
			width: 306px;
		}

	}

}