.postuler{

	&__tetiere{
		background: @noir url(../img/tetiere-recrutement.jpg) no-repeat center;
		background-size: cover;
		color: #FFFFFF;
		padding: 40px 20px;

		h1{
			font-size: 30px;
			text-transform: uppercase;
			line-height: 1.2;
			margin-bottom: 36px;
			font-family: "Raleway-Black";
		}
		span{
			display: inline-block;
		}

	}


	&__forms{
		max-width: 720px;
		min-height: 500px;
		margin: 0 auto;
		padding: 16px;

		.form-hidden{
			display: none;
		}
	}

	.autre-experience{
		display: none;
	}


	&__table-form{
		//background: red;
		width: 100%;
		text-align: left;
		
		td,
		div{
			display: block;
			//background: green;
			margin: 6px 0;
			position: relative;

			input[type="text"],
			select,
			textarea{
				width: 100%;
				margin: 0;
				padding: 8px 10px;
				border: 1px solid #000000;
				box-sizing: border-box;
				font-family: "Raleway-Regular";
			}

			.custom-select{
				position: relative;
				&:after{
				    content: "";
				    display: block;
				    position: absolute;
				    top: 50%;
				    right: 16px;
				    margin-top: -0.4em;
				    width: 0.4em;
				    height: 0.4em;
				    border-right: 0.15em solid #e67a2a;
				    border-top: 0.15em solid #e67a2a;
				    transform: rotate(135deg);
				}
				select{
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
					padding-right: 40px;
					background: #FFFFFF;
				}
			}

			.custom-radio{
				position: absolute;
				left: -9999px;
			}
			.custom-radio + label{
				padding-left: 24px;
				margin-left: 10px;
				cursor: pointer;
				position: relative;
			}
			.custom-radio + label:before{
				content: "";
				position: absolute;
				left: 0;
				top: -1px;
				width: 16px;
				height: 16px;
				border: 1px solid #000000;
				border-radius: 50%;
			}
			.custom-radio + label:after{
			    content: "";
				position: absolute;
				width: 10px;
				height: 10px;
				left: 4px;
				top: 3px;
			    -webkit-transition: all 0.3s ease;
			    -moz-transition: all 0.3s ease;
			    transition: all 0.3s ease;
			    border-radius: 50%;
			}
			.custom-radio:checked + label:after{
			    background: #e67a2a;
			    -webkit-transition: all 0.3s ease;
			    -moz-transition: all 0.3s ease;
			    transition: all 0.3s ease;
			}


			input[type="file"]{
				position: absolute;
				left: -9999px;
			}
			.ajouter-piece{
				color: #888888;
				display: block;
				cursor: pointer;
				position: relative;
				margin: 10px 0;

				&.showAutreExperience{
					margin-top: 40px;
				}

				span{
					display: block;
					margin-top: 8px;
					font-style: italic;
				}

				&:before{
					content: "+";
					position: absolute;
					width: 24px;
					height: 24px;
					line-height: 24px;
					border-radius: 50%;
					background: #e67a2a;
					right: 0;
					color: #FFFFFF;
					font-size: 40px;
					text-align: center;
					text-indent: 1px;
					font-family: "Raleway-Light";
				}
			}
            /* checbox custom */
            [type="checkbox"]:not(:checked),
            [type="checkbox"]:checked {
              position: absolute;
              left: -9999px;
            }
            [type="checkbox"]:not(:checked) + label,
            [type="checkbox"]:checked + label {
              position: relative;
              padding-left: 1.95em;
              cursor: pointer;
              display: block;
              text-align: left;
             //margin-bottom: 20px;
            }

            /* checkbox aspect */
            [type="checkbox"]:not(:checked) + label:before,
            [type="checkbox"]:checked + label:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 16px;
                height: 16px;
                border: 1px solid #000000;
                background: #fff;
                border-radius: 0;
                box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
            }
            /* checked mark aspect */
            [type="checkbox"]:not(:checked) + label:after,
            [type="checkbox"]:checked + label:after {
                font-family: @font_A;
                content: '\f00c';
                position: absolute;
                top: .1em; left: 0;
                font-size: 1.3em;
                line-height: 0.8;
                color: #e67a2a;
                transition: all .2s;
            }
            /* checked mark aspect changes */
            [type="checkbox"]:not(:checked) + label:after {
              opacity: 0;
              transform: scale(0);
            }
            [type="checkbox"]:checked + label:after {
              opacity: 1;
              transform: scale(0.8);
            }
            /* disabled checkbox */
            [type="checkbox"]:disabled:not(:checked) + label:before,
            [type="checkbox"]:disabled:checked + label:before {
              box-shadow: none;
              border-color: #bbb;
              background-color: #ddd;
            }
            [type="checkbox"]:disabled:checked + label:after {
              color: #999;
            }
            [type="checkbox"]:disabled + label {
              color: #aaa;
            }
            /* accessibility */
            [type="checkbox"]:checked:focus + label:before,
            [type="checkbox"]:not(:checked):focus + label:before {
              //border: 2px dotted blue;
            }

	
		}


		.td-alerte{
			text-align: center;
			padding: 20px 0 0;
		}
		.tr-section{
			td,
			div{
				padding: 30px 0 10px;
				font-family: "Raleway-Black";
				text-transform: uppercase;
				font-size: 16px;
			}
			
		}

		.tr-civilite{
			td,
			div{
				display: inline-block;
				margin: 20px 0 16px;
			}
		}

		.tr-date{
			td,
			div{
				input{
					width: 38%;
				}
				input:nth-of-type(1),
				input:nth-of-type(2){
					width: 23%;
				}
				.date-separator{
					display: inline-block;
					color: #e67a2a;
					width: 8%;
					text-align: center;
				}
			}
		}

		.asterisk{
			color: #e67a2a;
			font-size: 130%;
			vertical-align: middle;
		}


		.error{
			display: none;
			color : #e67a2a;
			font-size: 9px;
			border-radius: 2px;
			position: absolute;
			right: 0px;
			margin-top: -3px;
		}


		.tr-submit{
			text-align: center;
			.submit{
				background: #FFFFFF;
				box-sizing: content-box;
				min-width: 110px;
				margin-top: 20px;
			}
			small{
				font-size: 85%;
			}
		}


	}


	&__alerte{

		&__titre{
			font-size: 24px;
			text-transform: uppercase;
			margin: 20px 0;
			line-height: 1.2;
			span{
				display: block;
			}
		}

		.tr-submit{
			.submit{
				margin-top: 0;
			}
		}


	}



}


.fancybox-opened .fancybox-skin{
	border-radius: 0; 
	background: #FFFFFF;
	box-shadow: none; 
	overflow: hidden;
}
.fancybox-close{
	right: -3px;
	top: -3px;
	background: #e67a2a;
	-moz-transform: skewX(10deg) skewY(-5deg);
	-webkit-transform: skewX(10deg) skewY(-5deg);
	-o-transform: skewX(10deg) skewY(-5deg);
	-ms-transform: skewX(10deg) skewY(-5deg);
	transform: skewX(10deg) skewY(-5deg);
	width: 42px;
	height: 38px;
	&:before{
		content: "\2716";
		color: #FFFFFF;
		font-size: 18px;
		margin-top: 10px;
		display: block;
	}
}


/* only mobile */
@media screen and (max-width: 640px) {
	.postuler{

	}
}


@media screen and (min-width: 640px) {
	.postuler{

		&__tetiere{
			padding: 80px 20px;
			h1{
				font-size: 44px;
			}
			span{
				max-width: 860px;
				padding: 0 10%;
			}
		}


		&__form{
			margin-top: 50px;
		}


		&__table-form{
			width: 76%;
			text-align: left;
			
			td,
			div{
				display: block;
				display: table-cell;
				vertical-align: middle;
				padding: 8px 0;

				&:first-child{
					text-align: right;
					width: 36%;
					padding-right: 20px;

				}
			}


			.tr-civilite{
				td,
				div{
					display: table-cell;
					margin: 0;
					padding: 20px 20px 10px 0;
				}
			}


		}



		&__alerte{

			&__titre{
				font-size: 32px;
			}

		}



	}
}

#recrutement{
	.custom-select{
		padding-right: 0;
	}
	.input-date{
		.custom-select{
			width:47%;
			display:inline-block;

			&:nth-child(even){
				margin-left:9px;
			}
		}
	} 
}

@media screen and (min-width: 768px) {
	.postuler{

	}
}


@media screen and (min-width: 640px) and (max-width: 1023px) {
	.postuler{
		
	}
}


@media screen and (min-width: 1024px) {
	.postuler{
		
	}
}