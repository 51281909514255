@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon/icomoon.eot?-rdnm34');
	src:url('../fonts/icomoon/icomoon.eot?#iefix-rdnm34') format('embedded-opentype'),
		url('../fonts/icomoon/icomoon.woff?-rdnm34') format('woff'),
		url('../fonts/icomoon/icomoon.ttf?-rdnm34') format('truetype'),
		url('../fonts/icomoon/icomoon.svg?-rdnm34#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

div.cs-skin-border {
	background: transparent;
	font-size: 2em;
	font-weight: 700;
	max-width: 600px;
}

@media screen and (max-width: 30em) {
	div.cs-skin-border { font-size: 1em; }
}

.cs-skin-border > span {
	border: 5px solid #000;
	border-color: inherit;
	-webkit-transition: background 0.2s, border-color 0.2s;
	transition: background 0.2s, border-color 0.2s;
}

.cs-skin-border > span::after,
.cs-skin-border .cs-selected span::after {
	font-family: 'icomoon';
	content: '\e000';
}

.cs-skin-border ul span::after {
	content: '';
	opacity: 0;
}

.cs-skin-border .cs-selected span::after {
	content: '\e00e';
	color: #ddd9c9;
	font-size: 1.5em;
	opacity: 1;
	-webkit-transition: opacity 0.2s;
	transition: opacity 0.2s;
}

.cs-skin-border.cs-active > span {
	background: #fff;
	border-color: #fff;
	color: #2980b9;
}

.cs-skin-border .cs-options {
	color: #2980b9;
	font-size: 0.75em;
	opacity: 0;
	-webkit-transition: opacity 0.2s, visibility 0s 0.2s;
	transition: opacity 0.2s, visibility 0s 0.2s;
}

.cs-skin-border.cs-active .cs-options {
	opacity: 1;
	-webkit-transition: opacity 0.2s;
	transition: opacity 0.2s;
}

.cs-skin-border ul span {
	padding: 1em 2em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.cs-skin-border .cs-options li span:hover,
.cs-skin-border li.cs-focus span {
	background: #f5f3ec;
}
