.owl-nav{
	.owl-prev,
	.owl-next{
		position: absolute;
		top: 130px;
		display: block;
		width: 10px;
		height: 18px;
		overflow: hidden;
		color: transparent;
		background-position: 0 0;
		background-repeat: no-repeat;
		background-image: url(../img/carousel-fleche.svg);
		cursor: pointer;
	}
	.owl-prev{
		left: 15px;
		.mixin-rotate(180deg);
	}
	.owl-next{
		right: 15px;
	}

	&.disabled{
		display: none;		
	}
}

/*.owl-stage-outer{
	position: relative;
}*/
.owl-loaded{
	position: relative;
}

.owl-item{
	display: inline-block;
}

.owl-dots{
	width: 100%;
	text-align: center;

	&.disabled{
		display: none;
	}

	.owl-dot{
		display: inline-block;

		span{
			width: 6px;
			height: 6px;
			overflow: hidden;
			color: transparent;
			background-color: @noir;
			display: block;
			margin: 0 4px;
			.mixin-border-radius();
		}

		&.active{
			span{
				background-color: @orange;				
			}
		}
	}	
}